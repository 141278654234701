/* eslint-disable react-hooks/exhaustive-deps */

import "./AddCampaign.scss";

import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IAPIConfig, useApi } from "../../custom_hooks/useApi";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";
import CustomToast from "../../utils/cutomToast/CustomToast";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../../utils/loader/Loader";
import MenuItem from "@mui/material/MenuItem";
import SMSPreviewDrawer from "./SMSPreviewDrawer";

type TransformedObject = {
  Name: string;
  Link: string;
};

const SMS_EmailCampaign = () => {
  const navigate = useNavigate();
  const { doApi } = useApi();
  const location = useLocation();
  const data = location.state;
  const getActionParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get("action");
  };
  const [loading, setLoading] = useState(false);
  const [campaignActivites, setCampaignActivites] = useState<any>([]);
  const campaignActivity = useRef(true);
  const [editingActivity, setEditingActivity] = useState({
    name: "",
    daysLater: "",
    type: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState<string[]>(() => {
    if (!data?.campaign || typeof getActionParams !== "function") {
      return [];
    }
    if (
      getActionParams() === "update" &&
      data.campaign.Activities !== undefined &&
      data.campaign.Activities.length > 0
    ) {
      return data.campaign.Activities.map((activity: any) => activity.UniqueId);
    }
    return [];
  });

  const [campaignData, setcampaignData] = useState<TransformedObject>({
    Name: getActionParams() === "update" ? data.campaign.Name : "",
    Link: "SMS%2BEMAIL",
  });
  const [Draweropen, setDrawerOpen] = React.useState(false);
  const [clkPreview, setClkPreview] = useState("");

  const toggleDrawer = (newOpen: boolean, uniqueId?: string) => () => {
    setDrawerOpen(newOpen);
    setClkPreview(uniqueId || "");
  };

  const saveActivity = async (activity: any) => {
    try {
      setLoading(true);
      const createCampaignActivityConfig: IAPIConfig = {
        URL: `/Campaign/CreateCampaignActivity?userUniqueId=${data.usrUniqueId}`,
        method: "POST",
        payLoad: {
          businessId: data.businessId,
          locationId: data.locationId,
          name: activity.name,
          mode: editingActivity.type,
          link: { type: "default", url: "" },
          daysLater: activity.daysLater,
          emailInfo: {
            type: "",
            frequency: "",
            subject: "",
            content: "",
            fullContent: "",
            sendText: "",
          },
          smsInfo: {
            type: "",
            text: "",
            fullContent: ""
          },
          schedule: {
            sendOnDays: [""],
            sendAtTime: [
              {
                startTime: "",
                endTime: "",
              },
            ],
            count: 0,
          },
          timeSlots: {
            startTime: "",
            endTime: "",
          },
        },
      };
      const response: any = await doApi(
        createCampaignActivityConfig,
        "seedcore"
      );
      if (response?.data?.err === false) {
        CustomToast("Activity saved successfully!", "success");
        fetchCampaignActivites(campaignData.Link);
        setEditingActivity({ name: "", daysLater: "", type: "" });
        setIsEditing(false);
      } else {
        CustomToast(response?.data?.message, "error");
      }
    } catch (error: any) {
      CustomToast(error?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleAddActivity = () => {
    setIsEditing(true);
  };

  const handleActivityChange = (key: string, value: any) => {
    setEditingActivity({ ...editingActivity, [key]: value });
  };

  const handleClickOutside = () => {
    if (editingActivity) saveActivity(editingActivity);
  };

  // Fetch Campaign activites
  const fetchCampaignActivites = async (mode: string) => {
    setLoading(true);
    try {
      const fetchCampaignActivitesConfig: IAPIConfig = {
        URL: `/Campaign/GetCampaignActivities?userUniqueId=${data.usrUniqueId}&businessId=${data.businessId}&locationId=${data.locationId}&mode=${mode}`,
        method: "POST",
        payLoad: {},
      };
      const campaignActivites: any = await doApi(
        fetchCampaignActivitesConfig,
        "seedcore"
      );
      if (campaignActivites?.data.err === false) {
        const parseData = JSON.parse(campaignActivites?.data?.data);
        setCampaignActivites(parseData);
      } else {
        CustomToast(campaignActivites.data.message, "error");
      }
    } catch (error: any) {
      CustomToast(error?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  console.log("campaignActivites", JSON.stringify(campaignActivites));

  // Fetch Campaign activites
  const createCampaign = async () => {
    if (campaignData?.Name !== "" && getSelectedActivities().length > 0) {
      if (getSelectedActivities().some((activity: any) => {
        const emailContent = activity.EmailInfo !== undefined ? activity.EmailInfo?.FullContent?.trim() :true;
        const smsContent = activity.SmsInfo !== undefined ? activity.SmsInfo?.FullContent?.trim() : true;
        return !emailContent && !smsContent;
    })) {
        CustomToast("Some selected activities have empty htmlContent.", "error");
        return;
    }
      
      setLoading(true);
      let payload = {
        userUniqueId: data.usrUniqueId,
        businessId: data.businessId,
        name: campaignData?.Name,
        locationId: data.locationId,
        mode: "SMS&EMAIL",
        campaignId: "",
        noOfActivities: getSelectedActivities().length,
        activities: getSelectedActivities(),
        status: "scheduled",
        recipients: [],
        reviewLink: "",
        qrCodeType: "",
        qrCodePrintMsg: "",
        qrCodeMailMsg: "",
        genQRCode: "",
      };
      try {
        let campaignConfig: IAPIConfig;

        if (getActionParams() === "update") {
          payload = { ...payload, campaignId: data?.campaign?.UniqueId };
          campaignConfig = {
            URL: `/Campaign/UpdateCampaign?userUniqueId=${data.usrUniqueId}`,
            method: "POST",
            payLoad: payload,
          };
        } else {
          campaignConfig = {
            URL: `/Campaign/CreateCampaign?userUniqueId=${data.usrUniqueId}`,
            method: "POST",
            payLoad: payload,
          };
        }

        const campaignRes: any = await doApi(campaignConfig, "seedcore");
        if (campaignRes?.data.err === false) {
          CustomToast(campaignRes.data.message, "success");
          return true;
        } else {
          CustomToast(campaignRes.data.message, "error");
          return false;
        }
      } catch (error: any) {
        CustomToast(error?.message, "error");
        return false;
      } finally {
        setLoading(false);
      }
    } else {
      return true;
    }
  };
  const handleBack = async () => {
    debugger;
    const res = await createCampaign();
    if (res) {
      navigate(-1);
    }
  };

  const handleCheckboxChange = (activityId: string) => {
    setSelectedActivities((prevSelected) =>
      prevSelected.includes(activityId)
        ? prevSelected.filter((id) => id !== activityId)
        : [...prevSelected, activityId]
    );
  };

  // Get selected activities based on IDs
  const getSelectedActivities = () =>
    campaignActivites.filter((activity: any) =>
      selectedActivities.includes(activity.UniqueId)
    );

  useEffect(() => {
    if (
      data.usrUniqueId &&
      data.businessId &&
      data.locationId &&
      campaignActivity.current
    ) {
      fetchCampaignActivites(campaignData.Link);
      campaignActivity.current = false;
    }
  }, [campaignData.Link, data]);

  const handleCancel = () => {
    setIsEditing(false);
    setEditingActivity({ name: "", daysLater: "", type: "" });
  };

  const editMode = (activity: any) => {
    navigate(`/edit-${activity.Mode.toLowerCase()}-screen`, {
      state: {
        activity: activity,
        userUniqueId: data.usrUniqueId,
        campaignName: campaignData?.Name,
        action: getActionParams(),
      },
    });
  };

  return (
    <Box className="Add-Campaign-Container">
      <ArrowBackIcon
        sx={{
          marginBottom: "12px",
          color: "rgba(0, 0, 0, 0.56)",
          cursor: "pointer",
        }}
        onClick={() => handleBack()}
      />
      {/* Business Info Section */}
      <Box className="Campaign-info">
        <Typography variant="h5" sx={{ marginTop: 3 }}>
          New SMS/Email Campaign
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 3, marginBottom: 2 }}>
          Campaign Info
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            width: { xs: "100%", lg: "768px" },
            flexWrap: { xs: "wrap", lg: "nowrap" },
          }}
        >
          <TextField
            label="Campaign Name"
            variant="filled"
            fullWidth
            value={campaignData?.Name}
            onChange={(e) => {
              setcampaignData({ ...campaignData, Name: e.target.value });
            }}
          />

          <FormControl
            variant="filled"
            sx={{ minWidth: { xs: "100%", lg: "376px" } }}
          >
            <InputLabel id="CampaignType">Campaign Type</InputLabel>

            <Select
              labelId="CampaignType"
              id="CampaignType"
              value={campaignData?.Link}
              onChange={(e) => {
                setcampaignData({ ...campaignData, Link: e.target.value });
                campaignActivity.current = true;
              }}
              label="Campaign Type"
            >
              <MenuItem value="SMS%2BEMAIL">SMS + EMAIL(default)</MenuItem>
              <MenuItem value="SMS">SMS</MenuItem>
              <MenuItem value="EMAIL">EMAIL</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Typography variant="body1" sx={{ marginTop: 4 }}>
          Campaign Setup
        </Typography>
        <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
          Participant will move through the campaign only until review request
          is complete
        </Typography>
        <Loader spinning={loading}>
          <Box sx={{ marginTop: 2 }}>
            <Card sx={{ padding: 2, marginBottom: 3 }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{ flexWrap: "wrap", gap: 2 }}
              >
                {!isEditing ? (
                  <Card
                    sx={{
                      padding: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      flex: "none",
                    }}
                    onClick={handleAddActivity}
                  >
                    <AddIcon />
                    <Typography variant="body2">Add Activity</Typography>
                  </Card>
                ) : (
                  <>
                    {" "}
                    <TextField
                      label="Activity Name"
                      value={editingActivity.name}
                      onChange={(e) =>
                        handleActivityChange("name", e.target.value)
                      }
                      sx={{ flex: 1, minWidth: 200 }}
                    />
                    <TextField
                      label="Days Later"
                      type="number"
                      value={editingActivity.daysLater}
                      onChange={(e) =>
                        handleActivityChange("daysLater", e.target.value)
                      }
                      sx={{ flex: 1, minWidth: 150 }}
                    />
                    <FormControl
                      variant="filled"
                      sx={{ minWidth: { xs: "100%", lg: "376px" } }}
                    >
                      <InputLabel id="CampaignEditType">
                        Campaign Type
                      </InputLabel>

                      <Select
                        labelId="CampaignEditType"
                        id="CampaignEditType"
                        value={editingActivity.type}
                        onChange={(e) =>
                          handleActivityChange("type", e.target.value)
                        }
                        label="Campaign Type"
                      >
                        <MenuItem value="SMS">SMS</MenuItem>
                        <MenuItem value="EMAIL">EMAIL</MenuItem>
                      </Select>
                    </FormControl>
                    <Box>
                      <IconButton
                        color="primary"
                        sx={{
                          backgroundColor: "#0F4343",
                          color: "#FFFFFF",
                          marginRight: { xs: 1 },
                        }}
                        onClick={() => handleClickOutside()}
                      >
                        <DoneIcon
                          sx={{ width: "1.25rem", height: "1.25rem" }}
                        />
                      </IconButton>

                      <IconButton
                        color="primary"
                        sx={{ border: "1px solid #0F4343", marginTop: "4px" }}
                        onClick={() => handleCancel()}
                      >
                        <CloseIcon
                          sx={{ width: "1.25rem", height: "1.25rem" }}
                        />
                      </IconButton>
                    </Box>
                  </>
                )}
              </Stack>
            </Card>
            {campaignActivites.length > 0 &&
              campaignActivites.map((activity: any, index: number) => (
                <React.Fragment key={activity.UniqueId}>
                  <Card
                    sx={{
                      padding: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 2,
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        alignItems: "center",
                        color: "#0F4343",
                        cursor: "pointer",
                      }}
                    >
                      <Checkbox
                        key={activity?.UniqueId}
                        checked={selectedActivities.includes(activity.UniqueId)}
                        onChange={() => handleCheckboxChange(activity.UniqueId)}
                      />
                      <Box>
                        <Typography variant="subtitle1">
                          {activity.Name}
                        </Typography>
                        <Typography variant="body2">
                          <b>{activity.DaysLater}</b> day(s) later
                        </Typography>
                      </Box>
                    </Stack>
                    <Box>
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                          alignItems: "center",
                          color: "#0F4343",
                          cursor: "pointer",
                        }}
                      >
                        <Button
                          variant="text"
                          color="primary"
                          onClick={toggleDrawer(true, activity?.UniqueId)}
                        >
                          Preview
                        </Button>
                        <EditIcon
                          sx={{ width: "24px", height: "24px" }}
                          onClick={() => editMode(activity)}
                        />
                      </Stack>
                    </Box>
                  </Card>
                  {index < campaignActivites.length - 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: 1,
                        color: "#D3D3D3",
                      }}
                    >
                      <ArrowDownwardIcon sx={{ marginLeft: 10 }} />
                    </Box>
                  )}
                </React.Fragment>
              ))}
          </Box>
        </Loader>
        <SMSPreviewDrawer
          open={Draweropen}
          toggleDrawer={toggleDrawer}
          data={campaignActivites}
          preview={clkPreview}
          editModeOn={editMode}
        />
      </Box>
    </Box>
  );
};

export default SMS_EmailCampaign;
