import { Box, Button, Container, Typography } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate(); 
  
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Box mb={4}>
        <Button variant="outlined" onClick={handleBack} startIcon={<ArrowBackIcon/>}>
          Back
        </Button>
      </Box>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        Last Updated: January 17, 2025
      </Typography>
      <Typography variant="h6" gutterBottom>
        Introduction
      </Typography>
      <Typography variant="body1" paragraph>
        This Privacy Policy describes how Kanahiku LLC dba Massic ("Massic," "we," "us," or "Company") collects, stores, safeguards, and uses data and information (including non-public personal information, or "NPI") provided by users through:
        <ul>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Our website and its subdomains ("Site")</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Our B2B software-as-a-service platform and related APIs ("Platform")</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Content submitted by users</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Information collected through other means, including email, phone, or offline communications</li>
        </ul>
        These services are collectively referred to as the "Services". This policy is incorporated into and forms a material term of your registration and/or use of Massic's Services. By using any of the Services, you consent to the practices described in this Privacy Policy and the Massic Terms of Service.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Information We Collect
      </Typography>
      <Typography variant="body1" paragraph>
        Massic collects two types of information:
        <ol>
          <li><strong>Information You Provide to Us:</strong> Includes account creation details, business contact info, company information, user credentials, and more.</li>
          <li><strong>Information We Automatically Collect:</strong> Includes browser type, operating system, IP address, usage data, cookies, and other technical data.</li>
        </ol>
      </Typography>

      <Typography variant="h6" gutterBottom>
        How We Use Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We use collected information for the following purposes:
        <ul>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Providing and maintaining our Services</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Improving and optimizing our Services</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Ensuring security and preventing fraud</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Responding to your requests</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Processing payments and managing accounts</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Sending service updates and important notices</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Complying with legal obligations</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        AI and Machine Learning Processing
      </Typography>
      <Typography variant="body1" paragraph>
        Our Services incorporate AI and machine learning technologies. We process data through AI systems with appropriate safeguards, ensuring client data is protected. We do not use client data to train our AI models without explicit consent.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Information Sharing and Disclosure
      </Typography>
      <Typography variant="body1" paragraph>
        We do not sell your personal information. We may share data with:
        <ul>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Service Providers like cloud storage providers, payment processors, analytics services, etc.</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Business Transfers like mergers, acquisitions, or sales of assets.</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Legal Requirements including compliance with laws or legal processes.</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        Data Security
      </Typography>
      <Typography variant="body1" paragraph>
        We implement appropriate safeguards to protect your information, including encryption, access controls, regular security assessments, and backup systems. However, we cannot guarantee 100% security of your information.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Your Rights and Choices
      </Typography>
      <Typography variant="body1" paragraph>
        You have the right to:
        <ul>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Access and update your information</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Export or delete your data</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Opt-out of marketing communications</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Manage your cookie preferences</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        California Privacy Rights
      </Typography>
      <Typography variant="body1" paragraph>
        For California residents:
        <ul>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>You can request deletion of your personal information</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>You can opt-out of the sale of personal information (although we do not sell personal information)</li>
          <li style={{listStyleType: 'disc', marginLeft: '20px' }}>You can access and port your personal information</li>
        </ul>
      </Typography>

      <Typography variant="h6" gutterBottom>
        International Data Transfers
      </Typography>
      <Typography variant="body1" paragraph>
        For international users, we process data in the United States and comply with applicable data protection laws, including GDPR, and implement appropriate safeguards for cross-border data transfers.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Children's Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        Our Services are not directed to children under 18. If we learn we have collected information from a child under 18, we will delete it.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Changes to This Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update this Privacy Policy from time to time. We will notify you of material changes by posting the updated policy on our Site, sending you an email notification, or displaying a notice in our Services.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Contact Information
      </Typography>
      <Typography variant="body1" paragraph>
        For questions about this Privacy Policy or our privacy practices, contact us at:
        <br />
        <strong>legal@massic.io</strong>
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
