import './BusinessLogin.scss'

import * as yup from "yup";

import { Formik, FormikHelpers, FormikProps } from 'formik';
import React,{ useContext, useEffect } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import  {useApi, IAPIConfig } from '../../../custom_hooks/useApi';
import { useLocation, useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CustomToast from '../../../utils/cutomToast/CustomToast';
import { GlobalContext } from '../../../utils/globalContext/GlobalContext';
import { InputAdornment } from '@mui/material';
import Loader from '../../../utils/loader/Loader';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { login } from "../../../store/authSlice";
import { urlConstants } from '../../../components/constants/globalConstants';
import { useDispatch } from "react-redux";
import { useState } from 'react';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {urlConstants.CopyRights}
    </Typography>
  );
}

interface FormValues {
  email: string;
  password: string;
}

const BusinessLogin = () => {
    const { doApi } = useApi();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;
  
    const { excludeFromTimeout, includeInTimeout } = useContext(GlobalContext);
  
    useEffect(() => {
      excludeFromTimeout('BusinessLogin');
  
      return () => {
        includeInTimeout('BusinessLogin');
      };
    }, [excludeFromTimeout, includeInTimeout]);
  
    const handleFormSubmit = async (
      values: FormValues,
      { resetForm }: FormikHelpers<FormValues>
    ) => {
      if(values.email.trim() === ""){
        CustomToast('Please enter email', 'error')
        return
      }
      if(values.password.trim() === ""){
        CustomToast('Please enter password', 'error')
        return
      }
      const loginPayLoad = {
        email: values.email,
        password: values.password,
        associatedBusiness: [state]
      };
      const loginConfig: IAPIConfig = {
        URL: "/business-login",
        method: "POST",
        payLoad: loginPayLoad,
      };
      setLoading(true);
      doApi(loginConfig, 'seedmain')
        .then((response: any) => {
          const res = response.data;
          if (res.success) {
            CustomToast(`Login Successful`, "success");
            const userResponse = res.data;
            const authData = {
              token: userResponse.token,
              userDetails: userResponse,
              businessData: state
            };
            dispatch(login(authData));
            navigate("/userdashboard");
        
          } else {
            CustomToast(res.message, "error");
          }
        })
        .catch((err: any) => {
          CustomToast(err, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    const handleTogglePassword = () => {
      setShowPassword(!showPassword);
    };
  
    const checkoutSchema = yup.object().shape({
      email: yup
        .string()
        .required("Email is required")
        .min(5, "Email is too short - it should min length 5 chars."),
      password: yup
        .string()
        .required("Password is required.")
        .min(5, "Password is too short - should be 5 chars minimum."),
      rememberMe: yup.boolean(),
    });
  
    const initialValues: FormValues = {
      email: "",
      password: "",
    };
  
    return (
        <Loader spinning={loading}>
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {state?.name} Login
            </Typography>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }: FormikProps<FormValues>) => (
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <div className="business-user-login-container">
                    <div style={{ width: "100%" }}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        id="txtUserName"
                        name="email"
                        size="small"
                        autoComplete="off"
                        error={!!touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        id="txtPassword"
                        size="small"
                        autoComplete="off"
                        error={!!touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Box
                                component="div"
                                onClick={handleTogglePassword}
                                onMouseDown={(event) => event.preventDefault()}
                                style={{
                                  backgroundColor: "none",
                                  marginTop: 5,
                                  cursor: "pointer",
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <button type="submit" id="btnLogin">
                      {loading ? <>Loading..</> : <>Login</>}
                    </button>
                  </div>
                </Box>
              )}
            </Formik>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
        </Loader>
    );
}

export default BusinessLogin