/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import "./BusinessReviews.scss";
import "./BusinessTable.scss";

import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  Rating,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IAPIConfig, useApi } from "../../custom_hooks/useApi";
import React, { useEffect, useRef, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CountryAutocomplete from "../../custom_hooks/CountryAutocomplete";
import CustomToast from "../../utils/cutomToast/CustomToast";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import DrawerComponent from "./DrawerComponent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LaunchIcon from "@mui/icons-material/Launch";
import Loader from "../../utils/loader/Loader";
import LockIcon from "@mui/icons-material/Lock";
import MenuItem from "@mui/material/MenuItem";
import Papa from "papaparse";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import StarIcon from "@mui/icons-material/Star";
import Switch from "@mui/material/Switch";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/system";

interface Review {
  Id: number;
  ReviewId: string;
  LocationId: string;
  ReviewerDisplayName: string;
  StarRating: "ONE" | "TWO" | "THREE" | "FOUR" | "FIVE";
  Comment: string;
  CreateTime: string;
  UpdateTime: string;
  Name: string;
  ReviewReplyComment: string;
  ReviewReplyUpdateTime: string;
  SuggestedResponse?: string;
}

const BusinessReviews = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  console.log("isMobile:", isMobile);

  const navigate = useNavigate();
  const { doApi } = useApi();
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [displayedReviews, setDisplayedReviews] = useState<Review[]>([]);
  const [page, setPage] = useState(1);
  const reviewsPerPage = 5;
  const reviewApiControl = useRef(false);
  const suggestResponse = useRef(true);
  const getCustRef = useRef(false);
  const getCampaignRef = useRef(true);
  const getRespondSettings = useRef(false);
  const [customers, setCustomers] = useState<any>([]);
  const [campaigns, setCampaigns] = useState<any>([]);
  const [qrCodeCampaigns, setQRCodeCampaigns] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState(3);
  const profileData = useSelector(
    (state: any) => state.businessDetails.profileDataByUniqueID
  );
  const [location, setLocation] = useState<string>("");

  const [isEditing, setIsEditing] = useState(false);
  const [newCustomer, setNewCustomer] = useState({
    Name: "",
    Phone: "",
    Email: "",
    CountryCode: "",
  });

  const handleInputChange = (field: string, value: string) => {
    setNewCustomer((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  //QrCode
  const [Draweropen, setDrawerOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };
  const [selectedQRData, setSelectedQRData] = useState<any>({});

  const handleOutsideClick = async () => {
    debugger;
    if (newCustomer.Name && newCustomer.Phone && newCustomer.Email) {
      try {
        const locationId = profileData.Locations.filter(
          (val: any) => val.Name === location
        )[0].Id;
        const payload = {
          businessId: profileData.UniqueId,
          name: newCustomer.Name,
          email: newCustomer.Email,
          phone: newCustomer.Phone,
          locationName: location,
          locationId: locationId,
        };
        const customersAddConfig: IAPIConfig = {
          URL: `/Contacts/CreateContact?userUniqueId=${profileData.UserUniqueId}`,
          method: "POST",
          payLoad: payload,
        };

        const res: any = await doApi(customersAddConfig, "seedcore");

        if (res.status === 200 || res.data.err === false) {
          CustomToast("contacts added successfully!", "success");
          setIsEditing(false);
          setNewCustomer({
            Name: "",
            Phone: "",
            Email: "",
            CountryCode: "",
          });
          getCustRef.current = true;
        } else {
          CustomToast(res.data.message, "error");
        }
      } catch (error) {
        CustomToast("Create Contact Failed. Please try again.", "error");
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      reviewApiControl.current = true;
      suggestResponse.current = true;
    }
    if (newValue === 1) {
      getRespondSettings.current = true;
    }
    if (newValue === 2) {
      getCustRef.current = true;
    }
    if (newValue === 3) {
      getCampaignRef.current = true;
    }
    if (selectedTab === 1 && newValue !== 1) {
      updateResponderSettings();
    }
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (reviewApiControl.current && location !== "" && selectedTab === 0) {
      fetchReviews(location);
      reviewApiControl.current = false;
    }
  }, [reviewApiControl.current, location, selectedTab]);

  useEffect(() => {
    if (getCustRef.current && location !== "" && selectedTab === 2) {
      fetchCustomers(location);
      getCustRef.current = false;
    }
  }, [getCustRef.current, location, selectedTab]);

  useEffect(() => {
    if (getCampaignRef.current && location !== "" && selectedTab === 3) {
      fetchCampaigns(location);
      getCampaignRef.current = false;
    }
  }, [getCampaignRef.current, location, selectedTab]);

  useEffect(() => {
    if (getRespondSettings.current && location !== "" && selectedTab === 1) {
      fetchResponderSettings(location);
      getRespondSettings.current = false;
    }
  }, [getRespondSettings.current, location, selectedTab]);

  useEffect(() => {
    if (reviews.length > 0) {
      setDisplayedReviews(reviews.slice(0, reviewsPerPage));
      setPage(1);
    } else {
      setDisplayedReviews([]);
      setPage(1);
    }
  }, [reviews]);
  const loadMoreReviews = () => {
    const nextPage = page + 1;
    const newDisplayedReviews = reviews.slice(0, nextPage * reviewsPerPage);
    setDisplayedReviews(newDisplayedReviews);
    setPage(nextPage);
    suggestResponse.current = true;
  };

  const mapStarRating = (rating: Review["StarRating"]) => {
    switch (rating) {
      case "ONE":
        return "1.0";
      case "TWO":
        return "2.0";
      case "THREE":
        return "3.0";
      case "FOUR":
        return "4.0";
      case "FIVE":
        return "5.0";
      default:
        return "0.0";
    }
  };

  const convertKeysToLowerCase = (
    obj: Record<string, any>
  ): Record<string, any> => {
    const newObj: Record<string, any> = {};
    Object.keys(obj).forEach((key) => {
      const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
      newObj[lowerCaseKey] = obj[key];
    });
    return newObj;
  };

  const handleSeeSuggestedResponse = async () => {
    setLoading(true);
    try {
      const responses: any = await Promise.all(
        displayedReviews.map(async (review: any) => {
          if (review.Comment.trim() !== "") {
            const reviewPayload = convertKeysToLowerCase(review);
            const storeReviewsConfig: IAPIConfig = {
              URL: `/Review/GenerateAssistedReplies`,
              method: "POST",
              payLoad: reviewPayload,
            };
            try {
              const res: any = await doApi(storeReviewsConfig, "seedcore");
              if (res.status === 200 && res.data.err === undefined) {
                const data = JSON.parse(res.data.data);
                return {
                  success: true,
                  reviewId: review.ReviewId,
                  suggestedResponse: data[0],
                };
              } else {
                return {
                  success: false,
                  error: "No Data Found.",
                  reviewId: review.ReviewId,
                };
              }
            } catch (error: any) {
              return {
                success: false,
                error: error.message,
                reviewId: review.ReviewId,
              };
            }
          } else {
            return {
              success: false,
              error: "No Data Found.",
              reviewId: review.ReviewId,
            };
          }
        })
      );
      const successfulResponses = responses.filter(
        (response: any) => response.success
      );
      const failedResponses = responses.filter(
        (response: any) => !response.success
      );

      setDisplayedReviews((prevReviews) => {
        return prevReviews.map((review) => {
          const response = successfulResponses.find(
            (res: any) => res.reviewId === review.ReviewId
          );
          if (response) {
            return {
              ...review,
              SuggestedResponse: response.suggestedResponse?.trim(),
            };
          }
          return review;
        });
      });

      if (successfulResponses.length) {
        console.log(
          "Successful Responses:",
          successfulResponses.map((response: any) => response.suggestedResponse)
        );
      }

      if (failedResponses.length) {
        console.error(
          "Failed Responses:",
          failedResponses.map((response: any) => response.error)
        );
        console.log(failedResponses[0].error);
      } else {
        CustomToast("All reviews processed successfully.", "success");
      }
    } catch (error: any) {
      console.error(error);
      CustomToast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadTemplate = () => {
    const csvTemplate = [
      ["name", "email", "countrycode", "phone"], // Header row
      ["John Doe", "john@example.com", "+1", "1234567890"], // Example row
    ];
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvTemplate.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    saveAs(encodedUri, "addcustomerstemplate.csv");
  };

  const handleImportCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    event.preventDefault();
    const fileInput = event.target;
    const file = fileInput.files?.[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        try {
          const businessId = profileData.UniqueId;
          const locationId = profileData.Locations.filter(
            (val: any) => val.Name === location
          )[0].Id;
          const dataWithBusinessId: any = results.data.map((item: any) => ({
            ...item,
            businessId: businessId,
            locationId: locationId,
            locationName: location,
          }));

          const customersUploadConfig: IAPIConfig = {
            URL: `/Contacts/UploadContacts?userUniqueId=${profileData.UserUniqueId}`,
            method: "POST",
            payLoad: dataWithBusinessId,
          };

          const res: any = await doApi(customersUploadConfig, "seedcore");

          if (res.status === 200 || res.data.err === false) {
            CustomToast("CSV imported successfully!", "success");
            getCustRef.current = true;
          } else {
            CustomToast(res.data.message || "CSV import failed!", "error");
          }
        } catch (error) {
          CustomToast("Failed to upload CSV. Please try again.", "error");
        } finally {
          fileInput.value = ""; // Reset the file input value
          setLoading(false);
        }
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
        CustomToast("Failed to parse CSV. Please check the file.", "error");
        fileInput.value = ""; // Reset the file input value
      },
    });
  };

  const fetchReviews = async (locationId: string) => {
    try {
      setLoading(true);
      const storeReviewsConfig: IAPIConfig = {
        URL: `/Review/FetchReviews?accountManagerId=f23da09c-b86e-4a03-80f6-2b9175250924&nameFieldInAccount=accounts/114706490255130634915&nameFieldInLocation=${locationId}`,
        method: "GET",
      };
      const res: any = await doApi(storeReviewsConfig, "seedcore");
      if (res.status === 200 && res.data.err === undefined) {
        const result = JSON.parse(res.data.data);
        setReviews(result);
      } else {
        setReviews([]);
        CustomToast("No Data Found.", "error");
      }
    } catch (error: any) {
      console.error(error);
      setReviews([]);
      setLoading(false);
      CustomToast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomers = async (locationId: string) => {
    try {
      setLoading(true);
      const customersConfig: IAPIConfig = {
        URL: `/Contacts/GetActiveContacts?userUniqueId=${profileData.UserUniqueId}&businessId=${profileData.UniqueId}&locationId=${locationId}`,
        method: "POST",
        payLoad: {},
      };
      const res: any = await doApi(customersConfig, "seedcore");
      if (res.status === 200 || res.data.err === false) {
        const result = JSON.parse(res.data.data);
        setCustomers(result);
      } else {
        setCustomers([]);
        CustomToast("No Data Found.", "error");
      }
    } catch (error: any) {
      console.error(error);
      setCustomers([]);
      setLoading(false);
      CustomToast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaigns = async (location: string) => {
    const locationId = profileData.Locations.filter(
      (val: any) => val.Name === location
    )[0].Id;

    try {
      setLoading(true);
      const campaignConfig: IAPIConfig = {
        URL: `/Campaign/GetCampaigns?userUniqueId=${profileData.UserUniqueId}&businessId=${profileData.UniqueId}&locationId=${locationId}&mode=`,
        method: "POST",
        payLoad: {},
      };
      const res: any = await doApi(campaignConfig, "seedcore");
      if (res.status === 200 || res.data.err === false) {
        const result = JSON.parse(res.data.data);
        // Separate the activities by Mode
        const qrCodeActivities = result.filter(
          (activity: any) => activity.Mode === "QRCODE"
        );
        const smsEmailActivities = result.filter(
          (activity: any) => activity.Mode === "SMS&EMAIL"
        );
        setCampaigns(smsEmailActivities);
        setQRCodeCampaigns(qrCodeActivities);
      } else {
        setCampaigns([]);
        setQRCodeCampaigns([]);
        CustomToast("No Data Found.", "error");
      }
    } catch (error: any) {
      console.error(error);
      setCampaigns([]);
      setQRCodeCampaigns([]);
      setLoading(false);
      CustomToast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const deleteCustomerContact = async (Id: string) => {
    try {
      setLoading(true);
      const deleteCustomerConfig: IAPIConfig = {
        URL: `/Contacts/DeleteContact?userUniqueId=${profileData.UserUniqueId}&businessId=${profileData.UniqueId}&id=${Id}`,
        method: "POST",
        payLoad: {},
      };
      const res: any = await doApi(deleteCustomerConfig, "seedcore");
      if (res.status === 200 || res.data.err === false) {
        CustomToast(res?.data?.message, "success");
        getCustRef.current = true;
      } else {
        CustomToast(res?.data?.message, "error");
      }
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      CustomToast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateSuggestedResponse = (reviewId: string, value: string) => {
    setDisplayedReviews((prevReviews) =>
      prevReviews.map((review) =>
        review.ReviewId === reviewId
          ? { ...review, SuggestedResponse: value }
          : review
      )
    );
  };

  const handleClearSuggestedResponse = (reviewId: string) => {
    handleUpdateSuggestedResponse(reviewId, "");
  };

  const handlePostReview = async (reviewId: string) => {
    const reviewToPost = reviews.find((review) => review.ReviewId === reviewId);
    if (reviewToPost) {
      const storeReviewsConfig: IAPIConfig = {
        URL: `/Review/PostReply?accountManagerId=f23da09c-b86e-4a03-80f6-2b9175250924`,
        method: "PUT",
        payLoad: {
          reviewId: reviewToPost.ReviewId,
          reply: reviewToPost.SuggestedResponse,
        },
      };
      try {
        const res: any = await doApi(storeReviewsConfig, "seedcore");
        return {
          success: res.status === 200,
          reviewId: reviewToPost.ReviewId,
        };
      } catch (error: any) {
        return { success: false, reviewId: reviewToPost.ReviewId };
      }
    }
  };

  useEffect(() => {
    if (displayedReviews.length > 0 && suggestResponse.current) {
      handleSeeSuggestedResponse();
      suggestResponse.current = false;
    }
  }, [displayedReviews, suggestResponse.current]);
  useEffect(() => {
    if (profileData.Locations && profileData.Locations.length > 0) {
      setLocation(profileData.Locations[0].Name);
    }
  }, [profileData]);

  const [campaignOption, setCampaignOption] = React.useState("s/e");

  const handleChange = (event: React.MouseEvent<HTMLElement>, opt: string) => {
    setCampaignOption(opt);
  };

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handleCampaignTableCheckboxChange = (index: number) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(index) ? [] : [index]
    );
  };

  const [selectedQRRows, setSelectedQRRows] = useState<number[]>([]);

  const handleCampaignTableCheckboxQRChange = (index: number) => {
    setSelectedQRRows((prevSelected) =>
      prevSelected.includes(index) ? [] : [index]
    );
  };

  const [settings, setSettings] = useState({
    locationName: "",
    allReviews: "",
    incentiveDiscountSet: false,
    discountPrecentage: "",
    onlyFourAndAboveRatings: "",
    manualResponse: "",
    emailId: "",
    discountAmountPercentage: [],
  });

  // Fetch settings when entering the tab
  const fetchResponderSettings = async (location: string) => {
    setLoading(true);
    setSettings({
      locationName: "",
      allReviews: "",
      incentiveDiscountSet: false,
      discountPrecentage: "",
      onlyFourAndAboveRatings: "",
      manualResponse: "",
      emailId: "",
      discountAmountPercentage: [],
    });
    try {
      const fetchSettingsConfig: IAPIConfig = {
        URL: `/Review/FetchResponderSettings?userUniqueId=${profileData.UserUniqueId}&uniqueId=${profileData.UniqueId}&location=${location}`,
        method: "GET",
      };
      const fetchedSettings: any = await doApi(fetchSettingsConfig, "seedcore");
      if (fetchedSettings?.data.err === false) {
        if (fetchedSettings.data.data) {
          const finalObj = JSON.parse(fetchedSettings.data.data);
          setSettings({
            locationName: location,
            allReviews: finalObj.AllReviews ? "all" : "",
            incentiveDiscountSet: finalObj.IncentiveDiscountSet,
            discountPrecentage: finalObj.DiscountPrecentage,
            onlyFourAndAboveRatings: finalObj.OnlyFourAndAboveRatings
              ? "above4"
              : "",
            manualResponse: finalObj.ManualResponse ? "manual" : "",
            emailId: finalObj.EmailId,
            discountAmountPercentage: finalObj.DiscountAmountPercentage,
          });
        } else {
          CustomToast(fetchedSettings.data.message, "error");
        }
      } else {
        CustomToast(fetchedSettings.data.message, "error");
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch settings when entering the tab
  const updateResponderSettings = async () => {
    setLoading(true);
    const payload = {
      locationName: location,
      allReviews: settings.allReviews === "all" ? true : false,
      incentiveDiscountSet: settings?.incentiveDiscountSet,
      discountPrecentage: settings?.discountPrecentage,
      onlyFourAndAboveRatings:
        settings?.onlyFourAndAboveRatings === "above4" ? true : false,
      manualResponse: settings?.manualResponse === "manual" ? true : false,
      emailId: settings?.emailId,
      discountAmountPercentage: settings?.discountAmountPercentage,
    };
    try {
      const updateSettingsConfig: IAPIConfig = {
        URL: `/Review/UpdateResponderSettings?userUniqueId=${profileData.UserUniqueId}&uniqueId=${profileData.UniqueId}`,
        method: "POST",
        payLoad: payload,
      };
      const updateSettings: any = await doApi(updateSettingsConfig, "seedcore");
      if (updateSettings?.data.err === false) {
        console.log(updateSettings.data.message);
        setSettings({
          locationName: "",
          allReviews: "",
          incentiveDiscountSet: false,
          discountPrecentage: "",
          onlyFourAndAboveRatings: "",
          manualResponse: "",
          emailId: "",
          discountAmountPercentage: [],
        });
      } else {
        console.log(updateSettings.data.message);
      }
    } catch (error: any) {
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const newCampaignHandler = () => {
    const locationId = profileData.Locations.filter(
      (val: any) => val.Name === location
    )[0].Id;
    const data = {
      usrUniqueId: profileData.UserUniqueId,
      businessId: profileData.UniqueId,
      locationId: locationId,
    };
    if (campaignOption === "s/e") {
      navigate("/new-sms-email-campaign", { state: data });
      setLoading(true);
    } else {
      navigate("/new-campaign", { state: data });
    }
  };

  // const [editCampaignPopUp, setEditCampaignPopUp] = useState(false);
  // const [selectedCampaign, setSelectedCampaign] = useState<any>({});
  // const [handleForNewPopUp, setHandleForNewPopUp] = useState(false);

  const updateCampaignHandler = (campaign: any) => {
    const locationId = profileData.Locations.filter(
      (val: any) => val.Name === location
    )[0].Id;
    const data = {
      usrUniqueId: profileData.UserUniqueId,
      businessId: profileData.UniqueId,
      locationId: locationId,
      campaign: campaign,
    };
    if (campaignOption === "s/e") {
      navigate("/new-sms-email-campaign?action=update", { state: data });
      setLoading(true);
    } else {
      navigate("/new-campaign?action=update");
    }
  };

  // const handleForRunNew = () => {
  //   setHandleForNewPopUp(true);
  // };

  const handleManageClick = (index: number, camp: any) => {
    if (selectedRows.includes(index)) {
      updateCampaignHandler(camp);
      // setSelectedCampaign(camp);
      // setEditCampaignPopUp(true);
    } else {
      CustomToast("Please check the checkbox before clicking Manage", "error");
    }
  };

  const handleManageQRClick = (index: number, camp: any) => {
    if (selectedQRRows.includes(index)) {
      setDrawerOpen(true);
      setSelectedQRData(camp);
    } else {
      CustomToast("Please check the checkbox before clicking Email", "error");
    }
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => console.log("Link copied to clipboard!"),
      (err) => console.error("Failed to copy text: ", err)
    );
  };

  return (
    <Box className="reviews-container">
      {/* Profile Header */}

      <Typography variant="h4">Reviews</Typography>
      {/* Profile Tabs */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          flexWrap: "wrap",
          columnGap: "1.5rem",
          rowGap: "1.5rem",
          overflow: "hidden",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          className="reviews-tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Reviews" className="tab" />
          <Tab label="Responder Settings" className="tab" />
          <Tab label="Customers" className="tab" />
          <Tab label="Campaigns" className="tab" />
        </Tabs>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={location}
              onChange={(e) => {
                reviewApiControl.current = true;
                getCustRef.current = true;
                getRespondSettings.current = true;
                setLocation(e.target.value);
              }}
              sx={{
                color: "#6e6b6b",
                height: "40px",
                outline: "none",
                background: "#fff",
                border: "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              {profileData.Locations !== undefined &&
                profileData.Locations.map((loc: any, index: number) => (
                  <MenuItem key={loc.Name} value={loc.Name}>
                    {loc.DisplayName}- {index + 1}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Tab Content */}
      <Loader spinning={loading}>
        <Box className="review-tab-content">
          {selectedTab === 0 &&
            displayedReviews.map((review: Review, index: number) => (
              <Box sx={{ marginTop: 3 }} key={index}>
                <Card sx={{ borderRadius: 1, padding: 2 }}>
                  {/* <Typography variant="subtitle1">{review.Comment}</Typography> */}
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ alignItems: "center", marginTop: 1 }}
                  >
                    <Avatar
                      sx={{ width: "24px", height: "24px" }}
                      alt={review.ReviewerDisplayName}
                      src="/static/images/avatar/3.jpg"
                    />
                    <Typography variant="subtitle2">
                      {mapStarRating(review.StarRating)}
                    </Typography>
                    <Rating
                      name={`star-rating-${index}`}
                      value={parseFloat(mapStarRating(review.StarRating))}
                      readOnly
                      precision={0.1}
                      icon={
                        <StarIcon
                          fontSize="inherit"
                          sx={{
                            color: "#093535",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      }
                      emptyIcon={
                        <StarIcon
                          fontSize="inherit"
                          sx={{ color: "#ccc", width: "16px", height: "16px" }}
                        />
                      }
                    />
                  </Stack>
                  <Typography
                    sx={{
                      marginTop: 1,
                      color: "rgba(0,0,0,0.60)",
                      fontSize: "0.75rem",
                    }}
                    variant="body2"
                  >
                    {review.Comment}
                  </Typography>
                  <Box
                    key={review.ReviewId}
                    sx={{
                      marginTop: 3,
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 2,
                      flexWrap: { xs: "wrap", sm: "nowrap" },
                    }}
                  >
                    <TextField
                      sx={{
                        width: "100%",
                      }}
                      id={`review-${review.ReviewId}`}
                      value={review.SuggestedResponse || ""}
                      label="Generated Response"
                      variant="filled"
                      multiline
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) =>
                        handleUpdateSuggestedResponse(
                          review.ReviewId,
                          e.target.value
                        )
                      }
                    />

                    <Box>
                      <IconButton
                        color="primary"
                        sx={{
                          backgroundColor: "#0F4343",
                          color: "#FFFFFF",
                          marginRight: { xs: 1 },
                        }}
                        onClick={() => handlePostReview(review.ReviewId)}
                      >
                        <DoneIcon
                          sx={{ width: "1.25rem", height: "1.25rem" }}
                        />
                      </IconButton>

                      <IconButton
                        color="primary"
                        sx={{ border: "1px solid #0F4343", marginTop: "4px" }}
                        onClick={() =>
                          handleClearSuggestedResponse(review.ReviewId)
                        }
                      >
                        <CloseIcon
                          sx={{ width: "1.25rem", height: "1.25rem" }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </Card>
              </Box>
            ))}
          {selectedTab === 0 && displayedReviews.length < reviews.length && (
            <Box className="load-more-button-container">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={loadMoreReviews}
              >
                Load More
              </Button>
            </Box>
          )}
          {selectedTab === 1 && (
            <Box
              sx={{
                marginTop: 3,
                width: { xs: "100%", md: "100%", lg: "768px" },
              }}
            >
              <Typography variant="subtitle1">
                Automatically Respond to the Following Reviews
              </Typography>
              <Box
                sx={{
                  marginTop: 1,
                  padding: 2,
                  color: "#F6F8F8",
                  backgroundColor: settings.allReviews === "all" ? "#fff" : "",
                  borderRadius: 1,
                }}
              >
                <FormControl>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={settings.allReviews === "all"}
                        onClick={(e) => {
                          e.preventDefault();
                          if (settings.allReviews === "all") {
                            setSettings({
                              ...settings,
                              allReviews: "",
                              onlyFourAndAboveRatings: "",
                              manualResponse: "",
                            });
                          } else {
                            setSettings({
                              ...settings,
                              allReviews: "all",
                              onlyFourAndAboveRatings: "",
                              manualResponse: "",
                            });
                          }
                        }}
                        sx={{
                          color: "#0F4343",
                          "&.Mui-checked": { color: "#0F4343" },
                        }}
                      />
                    }
                    label="All Reviews"
                    sx={{ color: "#000000DE" }}
                  />
                </FormControl>

                <Divider
                  sx={{
                    borderStyle: "dotted",
                    height: "1px",
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                />

                {false && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={settings.incentiveDiscountSet}
                          onChange={(e) => {
                            setSettings((prev) => ({
                              ...prev,
                              incentiveDiscountSet: e.target.checked,
                            }));
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          sx={{ color: "gray", fontSize: "16px" }}
                        >
                          (Optional) Set an incentive discount for negative
                          reviews
                        </Typography>
                      }
                    />
                  </FormGroup>
                )}

                {/* Conditionally render content based on checkbox value */}
                {false && settings.incentiveDiscountSet && (
                  <Box sx={{ marginTop: 1 }}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Select a discount amount"
                      value={settings.discountPrecentage}
                      onChange={(e) =>
                        setSettings((prev: any) => ({
                          ...prev,
                          discountPrecentage: e.target.value,
                        }))
                      }
                      helperText="We recommend providing an incentive for those leaving negative reviews to give you another try"
                      sx={{
                        width: { md: "100%", lg: "696px" },
                        "& .MuiInputBase-root": {
                          padding: "0",
                          maxHeight: "40px",
                        },
                        "& .MuiInputLabel-root": {
                          top: "-6px",
                        },
                        marginLeft: "30px",
                      }}
                      InputProps={{
                        sx: {
                          padding: "0 0.75rem",
                        },
                      }}
                    >
                      {settings.discountAmountPercentage.map((option: any) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                )}
              </Box>

              {/* Additional Radio Button Options */}
              <Box
                sx={{
                  padding: 2,
                  backgroundColor:
                    settings.onlyFourAndAboveRatings === "above4" ? "#fff" : "",
                  borderRadius: 1,
                }}
              >
                <FormControl>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={settings.onlyFourAndAboveRatings === "above4"} // Controlled state
                        onClick={() => {
                          // Toggle logic: Check if the value is already set
                          if (settings.onlyFourAndAboveRatings === "above4") {
                            setSettings({
                              ...settings,
                              onlyFourAndAboveRatings: "", // Uncheck
                              allReviews: "",
                              manualResponse: "",
                            });
                          } else {
                            setSettings({
                              ...settings,
                              onlyFourAndAboveRatings: "above4", // Check
                              allReviews: "",
                              manualResponse: "",
                            });
                          }
                        }}
                        sx={{
                          color: "#0F4343",
                          "&.Mui-checked": { color: "#0F4343" },
                        }}
                      />
                    }
                    label="Only 4.0 and above"
                    sx={{ color: "#000000DE" }}
                  />
                </FormControl>
              </Box>

              <Box
                sx={{
                  padding: 2,
                  borderRadius: 1,
                  backgroundColor:
                    settings.manualResponse === "manual" ? "#fff" : "",
                }}
              >
                <FormControl>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={settings.manualResponse === "manual"} // Controlled state
                        onClick={() => {
                          // Toggle logic: Check if the value is already set
                          if (settings.manualResponse === "manual") {
                            setSettings({
                              ...settings,
                              manualResponse: "", // Uncheck
                              onlyFourAndAboveRatings: "",
                              allReviews: "",
                            });
                          } else {
                            setSettings({
                              ...settings,
                              manualResponse: "manual", // Check
                              onlyFourAndAboveRatings: "",
                              allReviews: "",
                            });
                          }
                        }}
                        sx={{
                          color: "#0F4343",
                          "&.Mui-checked": { color: "#0F4343" },
                        }}
                      />
                    }
                    label="None. Manually Approve Each Response"
                    sx={{ color: "#000000DE" }}
                  />
                </FormControl>
              </Box>

              <Box sx={{ marginTop: 3 }}>
                <Typography variant="subtitle1">
                  Contact Information for Negative Reviews
                </Typography>
                <Box sx={{ marginTop: 1 }}>
                  <TextField
                    id="filled-basic"
                    label="Enter Email Address"
                    value={settings.emailId}
                    onChange={(e) => {
                      setSettings({ ...settings, emailId: e.target.value });
                    }}
                    variant="filled"
                    sx={{ width: "100%" }}
                    helperText="This information will be shared in responses to negative reviews so that customers can reach out with complaints and for resolutions."
                  />
                </Box>
              </Box>
            </Box>
          )}

          {selectedTab === 2 && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  columnGap: 3,
                  rowGap: 1,
                  marginTop: 3,
                  marginBottom: 3,
                  flexWrap: { xs: "wrap", lg: "nowrap" },
                }}
              >
                <Typography variant="body2" sx={{ color: "rgba(0,0,0,0.60)" }}>
                  Customer Information cannot be edited or removed once a review
                  request has been sent
                </Typography>
                <Stack direction="row" spacing={3}>
                  <Button variant="outlined" onClick={handleDownloadTemplate}>
                    Dowload CSV Template
                  </Button>
                  <label htmlFor="import-csv">
                    <Button variant="outlined" component="span">
                      Import CSV
                    </Button>
                    <input
                      type="file"
                      id="import-csv"
                      accept=".csv"
                      style={{ display: "none" }}
                      onChange={handleImportCSV}
                    />
                  </label>
                </Stack>
              </Box>
              <TableContainer>
                <Table className="business-table-container">
                  <TableHead className="table-header">
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Phone #</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Date Created</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography
                          variant="body1"
                          className="add-product"
                          onClick={() => setIsEditing(true)}
                        >
                          + Add Customer
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {isEditing && (
                      <TableRow>
                        <TableCell
                          contentEditable
                          onBlur={(e) => {
                            debugger;
                            const name = e.currentTarget.textContent || "";
                            handleInputChange("Name", name);

                            // Check if all fields are filled and valid
                            if (
                              name &&
                              newCustomer.Phone &&
                              newCustomer.Email.match(
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                              )
                            ) {
                              handleOutsideClick();
                            }
                          }}
                          suppressContentEditableWarning
                        >
                          {newCustomer.Name || "Enter Name"}
                        </TableCell>
                        <TableCell>
                          <CountryAutocomplete
                            onCountrySelect={(country: any) =>
                              handleInputChange("CountryCode", country)
                            }
                          />
                        </TableCell>
                        <TableCell
                          contentEditable
                          onBlur={(e) => {
                            debugger;
                            const input = e.currentTarget.textContent || "";

                            handleInputChange("Phone", input);
                            e.currentTarget.textContent = input; // Update view to formatted value.

                            // Check if all fields are filled and valid
                            if (
                              newCustomer.Name &&
                              input &&
                              newCustomer.Email.match(
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                              )
                            ) {
                              // Automatically save when all fields are filled correctly
                              handleOutsideClick();
                            }
                          }}
                          suppressContentEditableWarning
                        >
                          {newCustomer.Phone || "Enter Phone No"}
                        </TableCell>
                        <TableCell
                          contentEditable
                          onBlur={(e) => {
                            debugger;
                            const email = e.currentTarget.textContent || "";
                            const emailPattern =
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                            if (emailPattern.test(email)) {
                              handleInputChange("Email", email);
                            } else {
                              e.currentTarget.textContent = ""; // Clear invalid email.
                              CustomToast("Invalid email format", "error");
                            }

                            // Check if all fields are filled and valid
                            if (
                              newCustomer.Name &&
                              newCustomer.Phone &&
                              emailPattern.test(email)
                            ) {
                              // Automatically save when all fields are filled correctly
                              handleOutsideClick();
                            }
                          }}
                          suppressContentEditableWarning
                        >
                          {newCustomer.Email || "Enter Email"}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              setIsEditing(false);
                              setNewCustomer({
                                Name: "",
                                Phone: "",
                                Email: "",
                                CountryCode: "",
                              });
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}

                    {customers.length > 0 &&
                      customers.map((cus: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{cus.Name}</TableCell>
                          <TableCell>
                            {cus?.CountryCode}
                            {""}
                            {cus.Phone}
                          </TableCell>
                          <TableCell>{cus.Email}</TableCell>
                          <TableCell>
                            {dayjs(cus.CreateDateTime).format(
                              "YYYY. MM. DD - hh:mm A"
                            )}
                          </TableCell>
                          <TableCell>
                            {cus.CampaignStatus === undefined ||
                            cus.CampaignStatus === "" ||
                            cus.CampaignStatus === null ? (
                              ""
                            ) : (
                              <Chip
                                label={cus.CampaignStatus}
                                sx={{
                                  fontSize: "12px",
                                  minHeight: "24px",
                                  height: "24px",
                                  bgcolor: "#0F4343",
                                  color: "#fff",
                                  paddingTop: "1px",
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              className="delete-icon"
                              onClick={(e: any) => {
                                e.preventDefault();
                                if (
                                  cus.Status === undefined ||
                                  cus.Status === "" ||
                                  cus.Status === null
                                ) {
                                  deleteCustomerContact(cus.Id);
                                }
                              }}
                            >
                              {cus.Status === undefined ||
                              cus.Status === "" ||
                              cus.Status === null ? (
                                <DeleteIcon
                                  className="icon"
                                  sx={{
                                    color: "#0F4343",
                                    width: "24px",
                                    height: "24px",
                                  }}
                                />
                              ) : (
                                <LockIcon
                                  className="icon"
                                  sx={{
                                    color: "#3D8E1D",
                                    width: "24px",
                                    height: "24px",
                                  }}
                                />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          {selectedTab === 3 && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  columnGap: 3,
                  rowGap: 1,
                  marginTop: 3,
                  marginBottom: 3,
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                }}
              >
                <ToggleButtonGroup
                  color="primary"
                  value={campaignOption}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                  sx={{
                    height: "38px",
                    color: "#0F4343",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  <ToggleButton value="s/e">SMS/Emails</ToggleButton>
                  <ToggleButton value="qr">QR Codes</ToggleButton>
                </ToggleButtonGroup>
                <Stack direction="row">
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={newCampaignHandler}
                  >
                    New Campaign
                  </Button>
                </Stack>
              </Box>

              {/* <Dialog
                open={editCampaignPopUp}
                onClose={() => setEditCampaignPopUp(false)}
              >
                <DialogContent>
                  <Typography variant="h6" align="center">
                    What would you like to do?
                  </Typography>
                  <Box display="flex" justifyContent="center" gap={2} mt={2}>
                    <Button
                      variant="outlined"
                      onClick={() => updateCampaignHandler(selectedCampaign)}
                    >
                      Edit Campaign
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleForRunNew}
                    >
                      Run For New
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>

              <Dialog open={handleForNewPopUp} onClose={()=> setHandleForNewPopUp(false)}>
                <DialogContent>
                  <Typography variant="h6" align="center">
                    Run For{" "}
                    <span style={{ fontWeight: 600, color: "#1976d2" }}>
                      {customers.length}
                    </span>{" "}
                    New Customers?
                  </Typography>
                  <Box display="flex" justifyContent="center" gap={2} mt={2}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={()=> {
                        setHandleForNewPopUp(false);
                        setEditCampaignPopUp(false);
                        setSelectedRows([]);
                      }}
                    >
                      Exit
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={()=>{
                        CustomToast("Success! Your campaign has been scheduled.", "success");
                        setHandleForNewPopUp(false);
                        setEditCampaignPopUp(false);
                        setSelectedRows([]);
                      }}
                    >
                      Yes
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog> */}

              <TableContainer>
                {campaignOption === "s/e" && (
                  <Table className="business-table-container">
                    <TableHead className="table-header">
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Campaign Name</TableCell>
                        <TableCell>Total Clicks</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {campaigns.length > 0 &&
                        campaigns.map((camp: any, index: number) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor: selectedRows.includes(index)
                                ? "rgba(51, 132, 132, 0.08)"
                                : "transparent", // Change color if selected
                            }}
                          >
                            <TableCell>
                              <Checkbox
                                sx={{ color: "#0F4343" }}
                                checked={selectedRows.includes(index)}
                                onChange={() =>
                                  handleCampaignTableCheckboxChange(index)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              {dayjs(camp.CreatedDateTime).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell sx={{ width: "50%" }}>
                              {camp.Name}
                            </TableCell>
                            <TableCell>0</TableCell>
                            <TableCell
                              sx={{
                                color: "#0F4343",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              <Stack
                                direction="row"
                                sx={{ alignItems: "center", cursor: "pointer" }}
                                onClick={() => handleManageClick(index, camp)}
                              >
                                Manage{" "}
                                <KeyboardArrowRightIcon
                                  sx={{ width: "24px", height: "24px" }}
                                />
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
                {campaignOption === "qr" && (
                  <Table className="business-table-container">
                    <TableHead className="table-header">
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Campaign Name</TableCell>
                        <TableCell>QR Code</TableCell>
                        <TableCell>Link</TableCell>
                        <TableCell>Total Scans</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {qrCodeCampaigns.length > 0 &&
                        qrCodeCampaigns.map((camp: any, index: number) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor: selectedRows.includes(index)
                                ? "rgba(51, 132, 132, 0.08)"
                                : "transparent",
                            }}
                          >
                            <TableCell>
                              <Checkbox
                                sx={{ color: "#0F4343" }}
                                checked={selectedQRRows.includes(index)}
                                onChange={() =>
                                  handleCampaignTableCheckboxQRChange(index)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              {dayjs(camp.CreatedDateTime).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "35%",
                                ...(camp.status === "InActive" && {
                                  color: "rgba(0, 0, 0, 0.6)",
                                }),
                              }}
                            >
                              {camp.Name}
                              {camp.status === "InActive" && (
                                <Chip
                                  label={"Inactive"}
                                  sx={{
                                    fontSize: "12px",
                                    minHeight: "24px",
                                    height: "1.25rem",
                                    bgcolor: "rgba(0, 0, 0, 0.12)",
                                    color: "rgba(0, 0, 0, 0.6)",
                                    paddingTop: "1px",
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              <img
                                src={camp.QRCode}
                                style={{ width: "40px", height: "40px" }}
                              ></img>
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={2}>
                                <LaunchIcon
                                  sx={{
                                    width: "1.25rem",
                                    height: "1.25rem",
                                    color: "#0F4343",
                                  }}
                                  onClick={() =>
                                    window.open(camp.QRCodeReviewLink, "_blank")
                                  }
                                />
                                <CopyAllIcon
                                  sx={{
                                    width: "1.25rem",
                                    height: "1.25rem",
                                    color: "#0F4343",
                                  }}
                                  onClick={() =>
                                    handleCopyToClipboard(camp.QRCodeReviewLink)
                                  }
                                />
                              </Stack>
                            </TableCell>
                            <TableCell>0</TableCell>
                            <TableCell
                              sx={{
                                color: "#0F4343",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              <Stack
                                direction="row"
                                sx={{ alignItems: "center" }}
                                onClick={() => handleManageQRClick(index, camp)}
                              >
                                Email{" "}
                                <KeyboardArrowRightIcon
                                  sx={{ width: "24px", height: "24px" }}
                                />
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
              <Stack
                direction="row"
                sx={{
                  marginTop: 3,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="Show Inactive"
                  />
                </FormGroup>
                {campaignOption === "qr" && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "rgba(0,0,0,0.60)",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    QR Codes from integrations cannot be edited or deleted
                  </Typography>
                )}
              </Stack>
            </Box>
          )}
        </Box>
        <DrawerComponent
          open={Draweropen}
          toggleDrawer={toggleDrawer}
          reviewLink={selectedQRData.QRCodeReviewLink}
          popUpData={selectedQRData}
          isEnable={true}
        />
      </Loader>
    </Box>
  );
};

export default BusinessReviews;
