/* eslint-disable react-hooks/exhaustive-deps */

import "./LoginPagePresentation.scss";

import * as yup from "yup";

import {
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Modal,
} from "@mui/material";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import { IAPIConfig, useApi } from "../../custom_hooks/useApi";
import React, { useContext, useEffect, useRef } from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import CustomToast from "../../utils/cutomToast/CustomToast";
import { GlobalContext } from "../../utils/globalContext/GlobalContext";
import { GoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";
import Loader from "../../utils/loader/Loader";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { login } from "../../store/authSlice";
import { urlConstants } from "../../components/constants/globalConstants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSendOtpEmail from "../../custom_hooks/useSendOtpEmail";
import { useState } from "react";

//InputAdornment

// import { Visibility, VisibilityOff } from "@mui/icons-material";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {urlConstants.CopyRights}
    </Typography>
  );
}

interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const LoginPagePresentation = () => {
  const { doApi } = useApi();
  // const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { sendOtpEmail } = useSendOtpEmail();
  const [loginPayload, setLoginPayload] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [modal, setModal] = useState(false);
  const [otp, setOtp] = useState<string[]>(["", "", "", "", ""]);
  const [verifyotp, setVerifyotp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showGoogleOnly, setShowGoogleOnly] = useState(false);
  const [userResponse, setUserResponse] = useState<any>([]);
  console.log(showGoogleOnly);
  const { excludeFromTimeout, includeInTimeout } = useContext(GlobalContext);

  // Avoid infinite re-render by using a ref flag for one-time logic
  const firstTime = useRef(true);

  useEffect(() => {
    if (firstTime.current) {
      const hostname = window.location.hostname;
      console.log("hostname", hostname);
      const shouldShowGoogleOnly = urlConstants.ALLOWED_HOSTNAMES.some(
        (allowedHostname) => hostname === allowedHostname.toLowerCase()
      );
      setShowGoogleOnly(shouldShowGoogleOnly);
      firstTime.current = false; // Ensure this runs only once
    }
  }, []);

  useEffect(() => {
    excludeFromTimeout("LoginPagePresentation");

    return () => {
      includeInTimeout("LoginPagePresentation");
    };
  }, []);

  const generateRandomNumberFunc = () => {
    const number = Math.floor(10000 + Math.random() * 90000).toString();
    return number;
  };

  const handleFormSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    if (values.email.trim() === "") {
      CustomToast("Please enter email", "error");
      return;
    }
    const payload = {
      email: values.email,
      password: "12345",
      rememberMe: values.rememberMe,
    };
    setLoginPayload(payload);
    const loginConfig: IAPIConfig = {
      URL: "/login",
      method: "POST",
      payLoad: payload,
    };
    setLoading(true);
    doApi(loginConfig, "seedmain")
      .then((response: any) => {
        const res = response.data;
        if (res.success) {
          const userResponse = res.data;
          const authData = {
            token: userResponse.token,
            userDetails: userResponse,
          };
          setUserResponse(authData);
          const otpValue = generateRandomNumberFunc();
          setVerifyotp(otpValue);
          sendOtpEmail(values.email, otpValue); //values.email , manikantaammayenni@gmail.com
          setModal(true);
        } else {
          CustomToast(res.message, "error");
          sessionStorage.setItem("auth", "0");
        }
      })
      .catch((err: any) => {
        CustomToast(err, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const handleTogglePassword = () => {
  //   setShowPassword(!showPassword);
  // };

  const checkoutSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .min(5, "Email is too short - it should min length 5 chars."),
    rememberMe: yup.boolean(),
  });

  const initialValues: FormValues = {
    email: localStorage.getItem("rememberedEmail") || "",
    password: localStorage.getItem("rememberedPassword") || "",
    rememberMe: localStorage.getItem("remembered") === "1" ? true : false,
  };

  const handleOtpChange = (value: string, index: number) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    if (value && index < 5) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      nextInput?.focus();
    }
  };

  const verifyOtp = () => {
    try {
      setLoading(true);
      const otpCode = otp.join("");
      if (otpCode === verifyotp) {
        dispatch(login(userResponse));
        if(userResponse.userDetails.roleid === 2){ 
          navigate("/settings");
        }else{
          navigate("/home");
        }
        if (loginPayload.rememberMe === true) {
          localStorage.setItem("rememberedEmail", loginPayload.email);
          localStorage.setItem("rememberedPassword", loginPayload.password);
          localStorage.setItem("remembered", "1");
        } else {
          localStorage.setItem("rememberedEmail", "");
          localStorage.setItem("rememberedPassword", "");
          localStorage.setItem("remembered", "0");
        }
      } else {
        CustomToast("Invalid otp", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const ConnectGoogle = () => {
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20profile%20email https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/analytics.edit
    https://www.googleapis.com/auth/userinfo.email&prompt=consent&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${urlConstants.ENV_URL}/signin-google&flowName=GeneralOAuthFlow&client_id=${urlConstants.CLIENT_ID}`;
  };

  const handleGoogleLogin = async (credentialResponse: any) => {
    setLoading(true);
    const googlePayload = {
      token: credentialResponse.credential,
    };
    try {
      const loginGoogleConfig: IAPIConfig = {
        URL: "/google-login",
        method: "POST",
        payLoad: googlePayload,
      };
      doApi(loginGoogleConfig, "seedmain")
        .then((response: any) => {
          const res = response.data;
          if (res.success) {
            CustomToast(`Login Successful`, "success");
            const userResponse = res.data;
            const authData = {
              token: userResponse.token,
              userDetails: userResponse,
            };
            dispatch(login(authData));
            if (authData.userDetails.agencyDetails.length === 0) {
              ConnectGoogle();
            }else{
                navigate("/home");
            }
          } else {
            CustomToast(res.message, "error");
            sessionStorage.setItem("auth", "0");
          }
        })
        .catch((err: any) => {
          CustomToast(err, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Google Login Failed:", error);
      setLoading(false);
    }
  };

  return (
    <Loader spinning={loading}>
      {!modal ? (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }: FormikProps<FormValues>) => (
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  {!showGoogleOnly ? (
                    <div className="login-container">
                      <div style={{ width: "100%" }}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          type="text"
                          label="Email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          id="txtUserName"
                          name="email"
                          size="small"
                          autoComplete="off"
                          error={!!touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                        />
                      </div>
                      {/* <div style={{ width: "100%" }}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          type={showPassword ? "text" : "password"}
                          label="Password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          name="password"
                          id="txtPassword"
                          size="small"
                          autoComplete="off"
                          error={!!touched.password && !!errors.password}
                          helperText={touched.password && errors.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Box
                                  component="div"
                                  onClick={handleTogglePassword}
                                  onMouseDown={(event) =>
                                    event.preventDefault()
                                  }
                                  style={{
                                    backgroundColor: "none",
                                    marginTop: 5,
                                    cursor: "pointer",
                                  }}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </Box>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div> */}
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      >
                        <Field
                          name="rememberMe"
                          type="checkbox"
                          as={Checkbox}
                          id="chkRemember"
                        />
                        <label>Remember Me</label>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        id="btnLogin"
                      >
                        {loading ? <>Loading..</> : <>Login</>}
                      </Button>
                      <div
                        style={{
                          width: "100%",
                          marginTop: "16px",
                          textAlign: "center",
                        }}
                      >
                        <Link
                          to="/privacy"
                          style={{
                            marginRight: "15px",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          Privacy Policy
                        </Link>
                        <span>|</span>
                        <Link
                          to="/terms"
                          style={{
                            marginLeft: "15px",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          Terms and Conditions
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "1rem",
                        }}
                      >
                        <GoogleLogin
                          onSuccess={handleGoogleLogin}
                          onError={() =>
                            CustomToast("Google Login Failed", "error")
                          }
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          marginTop: "16px",
                          textAlign: "center",
                        }}
                      >
                         <Link
                          to="/privacy"
                          style={{
                            marginRight: "15px",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          Privacy Policy
                        </Link>
                        <span>|</span>
                        <Link
                          to="/terms"
                          style={{
                            marginLeft: "15px",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          Terms and Conditions
                        </Link>
                      </div>
                    </>
                  )}
                </Box>
              )}
            </Formik>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      ) : (
        <Modal
          open={modal}
          onClose={(e, reason) => {
            if (reason === "backdropClick") {
              return;
            }
            setModal(false);
          }}
          aria-labelledby="otp-modal-title"
          aria-describedby="otp-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
              textAlign: "center",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography id="otp-modal-title" variant="h6" mb={2}>
                Enter OTP
              </Typography>

              {/* Close Button */}
              <IconButton
                onClick={() => setModal(false)}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Box display="flex" justifyContent="center" gap="10px" mb={2}>
              {otp.map((digit, index) => (
                <InputBase
                  key={index}
                  id={`otp-${index}`}
                  value={digit}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (/^\d*$/.test(value) && value.length <= 1) {
                      handleOtpChange(value, index);
                      // Move focus to the next cell if a value is entered
                      if (value !== "" && index < otp.length - 1) {
                        document.getElementById(`otp-${index + 1}`)?.focus();
                      }
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      if (otp[index] === "" && index > 0) {
                        // Move to the previous cell
                        document.getElementById(`otp-${index - 1}`)?.focus();
                      } else {
                        // Clear the current cell
                        handleOtpChange("", index);
                      }
                    }
                  }}
                  inputProps={{
                    style: {
                      textAlign: "center",
                    },
                  }}
                  sx={{
                    width: 40,
                    height: 40,
                    textAlign: "center !important",
                    border: "1px solid #ccc",
                    borderRadius: 1,
                    fontSize: "1.25rem",
                    "&:focus": { outline: "2px solid blue" },
                    padding: 0,
                  }}
                />
              ))}
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={verifyOtp}
              disabled={otp.some((digit) => !digit)}
              endIcon={
                loading ? <CircularProgress size={24} color="inherit" /> : null
              }
            >
              Verify OTP
            </Button>
          </Box>
        </Modal>
      )}
    </Loader>
  );
};

export default LoginPagePresentation;
