/* eslint-disable react-hooks/exhaustive-deps */

import "./SettingsBusinessProfile.scss";

import * as Yup from "yup";

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { IAPIConfig, useApi } from "../../../custom_hooks/useApi";
import React, { useEffect, useRef, useState } from "react";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddIcon from "@mui/icons-material/Add";
import { ArrowForward } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CommonModalPopUp from "./CommonModalPopUp";
import ConfirmationDialog from "../../../components/confirmationDailog/ConfirmationDialog";
import CustomToast from "../../../utils/cutomToast/CustomToast";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Google from "../../../components/svg/Google";
import GroupsIcon from "@mui/icons-material/Groups";
import Loader from "../../../utils/loader/Loader";
import ModalPopUpReNew from "./ModalPopUpReNew";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PaymentIcon from "@mui/icons-material/Payment";
import PaymentModalPopUp from "./PaymentModalPopUp";
import UploadIcon from "@mui/icons-material/Upload";
import person1 from "../../../assets/images/profile/person_1.png";
import { urlConstants } from "../../../components/constants/globalConstants";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import useRemoveAgencyData from "../../../custom_hooks/removeAgencyData";
import { useSelector } from "react-redux";
import useSendEmail from "../../../custom_hooks/sendEmail";
import useSendPushNotifications from "../../../custom_hooks/useSendPushNotifications";

// Define the UserPlan interface
interface UserPlan {
  amount: string;
  planName: string;
  planExpiry: string;
  nextPayment: string;
  billingFrequency: string;
}

// Define the PaymentMethod interface
interface PaymentMethod {
  id: number;
  cvv: string;
  last4: string;
  primary: boolean;
  cardHolder: string;
  cardNumber: string;
  expiryDate: string;
}

// Define the main UserData interface
interface UserData {
  Id: number;
  UserId: number;
  UserPlan: UserPlan;
  PaymentMethod: PaymentMethod[];
}

type TeamMember = {
  id: number;
  userName: string;
  email: string;
  logo: string;
  unqiueId: string;
  roleName: string; // Assuming these are the roles assigned to each member
};

type Member = {
  email: string;
  roleId: number; // Changed this to store role ID, which you map to a label
  url: string;
  inviteToken: string;
};

const SettingsBusinessProfile: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0); // State to track active tab
  const { userDetails } = useSelector((state: any) => state.auth);
  const { doApi } = useApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { sendPushNotifications } = useSendPushNotifications();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpenReNew, setModalOpenReNew] = useState(false);

  const [isModalOpenPayment, setModalOpenPayment] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const firstTime = useRef(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [userData, setUserData] = useState<UserData>({
    Id: -1,
    UserId: -1,
    UserPlan: {
      amount: "",
      planName: "",
      planExpiry: "",
      nextPayment: "",
      billingFrequency: "",
    },
    PaymentMethod: [
      {
        id: 1,
        cvv: "",
        last4: "",
        primary: true,
        cardHolder: "",
        cardNumber: "",
        expiryDate: "",
      },
    ],
  });

  const [selectPaymentMethod, setSelectPaymentMethod] = useState<PaymentMethod>(
    {
      id: -1,
      cvv: "",
      last4: "",
      primary: false,
      cardHolder: "",
      cardNumber: "",
      expiryDate: "",
    }
  );

  //Agency Dropdown

  const [selectedOption, setSelectedOption] = useState<string>("");

  const agencyDetails = userDetails?.agencyDetails || [
    { AuthId: "", DisplayName: "" },
  ];

  const handleDropdownChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleGoClick = () => {
    const emailId = userDetails?.agencyDetails.filter((val: any)=> val.AuthId === selectedOption)[0].DisplayName;
    navigate(`/signin-google?authId=${selectedOption}&emailId=${emailId}`);
  };

  //Teams

  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const firstTimeTeamMember = useRef(false);
  const [emailError, setEmailError] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newMemberEmail, setNewMemberEmail] = useState<string>("");
  const [newMemberRoleId, setNewMemberRoleId] = useState<number | null>(null); // Only store role ID
  const [invitedMembers, setInvitedMembers] = useState<Member[]>([]);
  const { sendEmailInvitation } = useSendEmail();
  const { removeCoreAgency } = useRemoveAgencyData();
  // Validate email format
  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailPattern.test(newMemberEmail));
  };
  useEffect (()=>{
     if(newMemberRoleId !== null){
       handleAddAnother();
     }
  }, [newMemberRoleId])

  const handleAddAnotherEmail = async() => {
      setNewMemberEmail("");
      setNewMemberRoleId(null);
  }
  // Add Another Member
  const handleAddAnother = async () => {
    setLoading(true);
    try {
      if (!newMemberEmail) {
        CustomToast("Please enter an email address.", "error");
        return;
      }
      if (newMemberRoleId === null) {
        CustomToast("Please select a role.", "error");
        return;
      }

      if (!emailError && newMemberEmail.trim() !== "") {
        const encryptConfig: IAPIConfig = {
          URL: "/encrypt",
          method: "POST",
          payLoad: { encryptedText: newMemberEmail.trim() },
        };
        const encryptRes: any = await doApi(encryptConfig, "seedmain");
        const inviteToken = encryptRes.data.data;
        setInvitedMembers((prevMembers) => [
          ...prevMembers,
          {
            email: newMemberEmail,
            roleId: newMemberRoleId,
            url: `${urlConstants.ENV_URL}/user-signup?token=${inviteToken}`,
            inviteToken: inviteToken,
          },
        ]);
      } else {
        validateEmail();
      }
    } finally {
      setLoading(false);
    }
  };

  // Send Invites (Placeholder logic)
  const handleSendInvites = async () => {
    if (invitedMembers.length === 0) {
      CustomToast("No members to invite.", "error");
      return;
    }
    try {
      setLoading(true);

      for (const member of invitedMembers) {
        const { email, url } = member;

        // API call to save the invite in the database (send-invites API)
        const sendInvitesConfig: IAPIConfig = {
          URL: "/send-team-invitations",
          method: "POST",
          payLoad: member,
        };
        const inviteRes: any = await doApi(sendInvitesConfig, "seedmain");
        if (!inviteRes.response && inviteRes.data.success === true) {
            sendEmailInvitation(email, url);
            setInvitedMembers([]);
        } else {
          CustomToast(
            `Failed to send invite to ${email}: ${
              inviteRes.response.data.message || inviteRes.data.message
            }`,
            "error"
          );
        }
      }
    } catch (err: any) {
      CustomToast(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMember = (index: number) => {
    setInvitedMembers((prevMembers) =>
      prevMembers.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    const fetchTeamMembers = async () => {
      // Sample data mimicking an API call response
      const data: TeamMember[] = [];
      setTeamMembers(data);
    };

    fetchTeamMembers();
  }, []);

  // Handle menu open
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle menu close
  const handleClose = () => {
    setAnchorEl(null);
  };

  const roleOptions = [
    { id: 101, label: "Admin" },
    { id: 102, label: "Editor" },
    { id: 103, label: "Viewer" },
  ];

  // Handle Role Change
  const handleRoleChange = (selectedRoleId: number) => {
    setNewMemberRoleId(selectedRoleId); // Store just the ID
  };

  const displaySelectedRoles = () => {
    const selectedRole = roleOptions.find(
      (role) => role.id === newMemberRoleId
    );
    return selectedRole ? selectedRole.label : "Select Role";
  };

  //Renew
  const handleOpenModalReNew = () => {
    setModalOpenReNew(true);
  };

  const handleCloseModalReNew = () => {
    setModalOpenReNew(false);
  };

  const handleReNew = () => {
    handleOpenModalReNew();
  };

  const handleExit = () => {
    handleCloseModalReNew();
  };

  //standard

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleStandard = () => {
    handleCloseModal();
  };

  const handleCancel = async () => {
    handleCloseModal();
    try {
      setLoading(true);
      const plansPayload = {
        Id: userData.Id,
        type: "CANCEL",
        userPlan: userData.UserPlan,
      };
      const renewAndCancelPlansConfig: IAPIConfig = {
        URL: "/renew-cancel-plans",
        method: "POST",
        payLoad: plansPayload,
      };
      const res: any = await doApi(renewAndCancelPlansConfig, "seedmain");
      if (res.data.success === true) {
        setModalOpenReNew(true);
        sendPushNotifications({
          title: `${userDetails.userName}(**** ****${userData.UserPlan.planName})`,
          body: res.data.message,
        });
        firstTime.current = true;
      } else {
        CustomToast(res.data.message, "error");
      }
    } catch (err: any) {
      CustomToast(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  //Payment

  // Open modal in Add mode
  const handleAddPaymentMethod = () => {
    setEditMode(false);
    setModalOpenPayment(true);
  };

  // Open modal in Edit mode
  const handleEditPaymentMethod = (index: number) => {
    const selectedPaymentMethod = userData.PaymentMethod[index];
    setSelectPaymentMethod({
      id: selectedPaymentMethod.id,
      cardNumber: selectedPaymentMethod.cardNumber,
      expiryDate: selectedPaymentMethod.expiryDate,
      cvv: selectedPaymentMethod.cvv,
      primary: selectedPaymentMethod.primary,
      last4: selectedPaymentMethod.last4,
      cardHolder: selectedPaymentMethod.cardHolder,
    });
    setEditMode(true);
    setModalOpenPayment(true);
  };

  const handleRemovePaymentMethod = async () => {
    const selectedPaymentMethod = userData.PaymentMethod[selectedIndex];
    const paymentPayload = {
      Id: userData.Id,
      type: "REMOVE",
      paymentData: {
        id: selectedPaymentMethod?.id,
        cvv: selectedPaymentMethod?.cvv,
        last4: selectedPaymentMethod?.last4,
        primary: selectedPaymentMethod?.primary,
        cardHolder: selectedPaymentMethod?.cardHolder,
        cardNumber: selectedPaymentMethod?.cardNumber,
        expiryDate: selectedPaymentMethod?.expiryDate,
      },
    };
    try {
      setLoading(true);
      const plansAndPaymentMethodsConfig: IAPIConfig = {
        URL: "/add-edit-remove-payment-method",
        method: "POST",
        payLoad: paymentPayload,
      };
      const res: any = await doApi(plansAndPaymentMethodsConfig, "seedmain");
      if (res.data.success === true) {
        sendPushNotifications({
          title: `${
            userDetails.userName
          }(**** ****${selectedPaymentMethod.cardNumber.slice(-4)})`,
          body: res.data.message,
        });
        firstTime.current = true;
      } else {
        CustomToast(res.data.message, "error");
      }
    } catch (err: any) {
      CustomToast(err.message, "error");
    } finally {
      setLoading(false);
      setOpenConfirmDialog(false);
    }
  };

  const handleConfirmDelete = (index: number) => {
    setSelectedIndex(index);
    setOpenConfirmDialog(true);
  };

  // Close the modal
  const handleCloseModalPayment = () => {
    firstTime.current = true;
    setModalOpenPayment(false);
  };

  useEffect(() => {
    if (!isModalOpenPayment) {
      setSelectPaymentMethod({
        id: 1,
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        primary: false,
        last4: "",
        cardHolder: "",
      });
    }
  }, [isModalOpenPayment]);

  const formik = useFormik({
    initialValues: {
      agencyName: userDetails.name || "",
      agencyWebsite: "",
      emailAddress: userDetails.email || "",
    },
    validationSchema: Yup.object({
      agencyName: Yup.string().required("Agency Name is required"),
      agencyWebsite: Yup.string()
        .url("Invalid URL")
        .required("Agency Website is required"),
      emailAddress: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      if (!selectedImage) {
        CustomToast("Please upload company logo", "error");
        setLoading(false);
        return;
      }
      try {
        const profilePayload = { ...values, logo: selectedImage };
        const settingsProfileConfig = {
          URL: "/create-settings-profile",
          method: "POST",
          payLoad: profilePayload,
        };
        const res: any = await doApi(settingsProfileConfig, "seedmain");
        if (res.data.success === true) {
          sendPushNotifications({
            title: `${values.agencyName}`,
            body: `Settings Profile created successfully`,
          });
          resetForm();
          setSelectedImage(null);
        } else {
          CustomToast("Settings Profile not created successfully", "error");
        }
      } catch (err: any) {
        CustomToast(err.message, "error");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleCreateUserSettingsProfile = () => {
    if (formik.dirty) {
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          formik.handleSubmit();
        }
      });
    }
  };

  const getPlansAndPaymentMethods = async () => {
    try {
      setLoading(true);
      const plansAndPaymentMethodsConfig: IAPIConfig = {
        URL: "/get-plans-and-payment-methods",
        method: "GET",
      };
      const res: any = await doApi(plansAndPaymentMethodsConfig, "seedmain");
      if (res.data.success === true) {
        setUserData(res.data.data);
      } else {
        CustomToast(res.data.message, "error");
      }
    } catch (err: any) {
      CustomToast(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const getTeamMembers = async () => {
    try {
      setLoading(true);
      const teamMembersConfig: IAPIConfig = {
        URL: "/get-team-members",
        method: "GET",
      };
      const res: any = await doApi(teamMembersConfig, "seedmain");
      if (!res.response && res.data.success === true) {
        setTeamMembers(res.data.data);
      } else {
        setTeamMembers([]);
        CustomToast(
          (res.response && res.response.data.message) || res.data.message,
          "error"
        );
      }
    } catch (err: any) {
      CustomToast(err.message, "error");
      setTeamMembers([]);
    } finally {
      setLoading(false);
    }
  };
   const handleRemoveAgencyDetails = (teamId: Number, name: string, userUniqueId: string) => {
    removeCoreAgency(userUniqueId, () => {
      removeMember(teamId, name);
    });
  };

  const removeMember = async (teamId: Number, email: string) => {
    try {
      setLoading(true);
      const removeMemberConfig: IAPIConfig = {
        URL: `/remove-team-member?teamId=${teamId}&email=${email}`,
        method: "GET",
      };
      const res: any = await doApi(removeMemberConfig, "seedmain");
      if (res.data.success === true) {
        sendPushNotifications({
          title: `${userDetails.userName}`,
          body: res.data.message,
        });
        getTeamMembers();
      } else {
        CustomToast(res.data.message, "error");
      }
    } catch (err: any) {
      CustomToast(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (firstTime.current && tabIndex === 1) {
      getPlansAndPaymentMethods();
      firstTime.current = false;
    } else if (firstTimeTeamMember.current && tabIndex === 2) {
      getTeamMembers();
      firstTimeTeamMember.current = false;
    }
  });

  useEffect(() => {
    if (selectedImage !== null) {
      handleCreateUserSettingsProfile();
    }
  }, [selectedImage]);

  // Trigger the file input click event using the ref
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handle the image selection
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      // Reset the input field so the same file can be selected again
      event.target.value = "";
      // Convert file to binary using FileReader
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = async () => {
        const binaryData = reader.result as ArrayBuffer;

        // Prepare form data for the API request
        const formData: any = new FormData();

        const fileBlob = new Blob([binaryData], { type: file.type });

        formData.append("UploadFile", fileBlob, file.name);

        try {
          setLoading(true);
          const logoUploadConfig: IAPIConfig = {
            URL: `/Storage/UploadToBlob?Container=usr&Entity=logo&ItemId=${userDetails.id}`,
            method: "POST",
            payLoad: formData,
          };
          const res: any = await doApi(logoUploadConfig, "seedcore");

          if (res.data.err === false) {
            setSelectedImage(res.data.uri);
          } else {
            setSelectedImage("");
          }
        } catch (err: any) {
          CustomToast(err.message, "error");
        } finally {
          setLoading(false);
        }
      };
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
    if (newIndex === 1) {
      firstTime.current = true;
    } else if (newIndex === 2) {
      getTeamMembers();
    }
  };

  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <form onSubmit={formik.handleSubmit}>
            <Box className="business-settings-form" key="firstTab">
              <Box className="business-settings-form-title">
                <Box className="business-settings-form-title-logo"></Box>
                <Typography
                  className="business-settings-form-title-text"
                  variant="h5"
                >
                  {userDetails.organzationName || ""}
                </Typography>
              </Box>
              <Box className="business-settings-form-profile">
                <Box className="business-settings-form-profile-left">
                  <Typography
                    className="business-settings-form-profile-left-text"
                    variant="subtitle1"
                  >
                    Agency Info
                  </Typography>
                  <TextField
                    variant="filled"
                    fullWidth
                    size="medium"
                    label="Agency Name"
                    name="agencyName"
                    value={formik.values.agencyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.touched.agencyName && formik.errors.agencyName
                    )}
                    helperText={
                      formik.touched.agencyName
                        ? String(formik.errors.agencyName)
                        : ""
                    }
                    className="business-settings-form-profile-left-agencyName"
                  />

                  <TextField
                    fullWidth
                    label="Agency Website"
                    name="agencyWebsite"
                    value={formik.values.agencyWebsite}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="filled"
                    size="medium"
                    error={Boolean(
                      formik.touched.agencyWebsite &&
                        formik.errors.agencyWebsite
                    )}
                    helperText={
                      formik.touched.agencyWebsite
                        ? String(formik.errors.agencyWebsite)
                        : ""
                    }
                    className="business-settings-form-profile-left-agencyWebsite"
                  />

                  <TextField
                    fullWidth
                    label="Email Address"
                    name="emailAddress"
                    value={formik.values.emailAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="filled"
                    size="medium"
                    error={Boolean(
                      formik.touched.emailAddress && formik.errors.emailAddress
                    )}
                    helperText={
                      formik.touched.emailAddress
                        ? String(formik.errors.emailAddress)
                        : ""
                    }
                    className="business-settings-form-profile-left-agencyEmailAddress"
                  />

                  {/* Upload Logo */}
                  <Box className="business-settings-form-profile-left-upload-control">
                    <Box className="business-settings-form-profile-left-upload-control-container">
                      {selectedImage && (
                        <Box
                          component="img"
                          src={selectedImage}
                          alt="Uploaded Logo"
                          className="business-settings-form-profile-left-upload-control-container-image-source"
                        />
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<UploadIcon />}
                        onClick={handleButtonClick}
                      >
                        Upload Logo
                      </Button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleImageUpload}
                      />
                    </Box>
                  </Box>
                </Box>
                {userDetails.roleid !== 2 && <Box className="business-settings-form-profile-right">
                  <Typography
                    className="business-settings-form-profile-right-text"
                    variant="subtitle1"
                  >
                    Linked Accounts
                  </Typography>
                  <Box className="business-settings-form-profile-right-container">
                    <Box className="business-settings-form-profile-right-container-top">
                      <Google />
                    </Box>
                    <Box className="business-settings-form-profile-right-container-middle">
                      <Typography
                        className="business-settings-form-profile-right-container-middle-text"
                        variant="h6"
                      >
                        Link Google Accounts
                      </Typography>
                      <Typography
                        className="business-settings-form-profile-right-container-middle-text"
                        variant="body2"
                      >
                        Connect Google accounts to view all your sites in a
                        single dashboard.
                      </Typography>
                    </Box>
                    <Box className="business-settings-form-profile-right-container-bottom">
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "max-content" }}
                        startIcon={<AddIcon />}
                        className="business-settings-form-profile-right-container-bottom-button"
                        onClick={() => {
                          ConnectGoogle();
                        }}
                      >
                        Connect
                      </Button>
                    </Box>
                    {Array.isArray(agencyDetails) &&
                      agencyDetails.length > 0 &&
                      agencyDetails[0].authId !== "" && (
                        <>
                          <Typography
                            sx={{
                              textAlign: "center",
                              my: 1,
                              fontWeight: "bold",
                              width: "100%",
                            }}
                            variant="body1"
                          >
                            (OR)
                          </Typography>

                          {/* <Typography
                            sx={{
                              textAlign: "left",
                              my: 1,
                              fontWeight: "bold",
                            }}
                            variant="body1"
                          >
                            Linked Accounts
                          </Typography> */}

                          {/* Dropdown and Go Button */}
                          <Box
                            className="business-settings-form-profile-radio-container"
                            display="flex"
                            alignItems="center"
                            gap={2}
                          >
                            <FormControl>
                              <RadioGroup
                                value={selectedOption}
                                onChange={(e) =>
                                  handleDropdownChange(e.target.value)
                                }
                              >
                                {Array.isArray(agencyDetails) &&
                                  agencyDetails.length > 0 &&
                                  agencyDetails[0].authId !== "" &&
                                  agencyDetails.map((agency, index) => (
                                    <FormControlLabel
                                      key={index}
                                      value={agency.AuthId}
                                      control={<Radio />}
                                      label={agency.DisplayName}
                                    />
                                  ))}
                              </RadioGroup>
                            </FormControl>
                            <IconButton
                              color="primary"
                              size="large"
                              onClick={handleGoClick}
                            >
                              <ArrowForward />
                            </IconButton>
                          </Box>
                        </>
                      )}
                  </Box>
                </Box>}
              </Box>
            </Box>
          </form>
        );
      case 1:
        return (
          <Box className="business-settings-form" key="secondTab">
            <Box className="business-settings-form-billing-header">
              {userData?.UserPlan !== null &&
              Object.keys(userData?.UserPlan).length > 0 &&
              userData?.UserPlan.planName !== "" ? (
                <>
                  <Box className="business-settings-form-billing-header-title">
                    {/* Bind Plan Name */}
                    <Typography variant="h5-serif">
                      {userData.UserPlan.planName}
                    </Typography>
                  </Box>
                  <Box className="business-settings-form-billing-header-title">
                    {/* Bind Plan Amount and Next Payment */}
                    <Typography variant="body1">
                      {userData.UserPlan.amount}
                    </Typography>
                    <Typography variant="body1">
                      Billed {userData.UserPlan.billingFrequency}. Next payment{" "}
                      {userData.UserPlan.nextPayment}
                    </Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={handleOpenModal}
                    size="medium"
                    color="primary"
                  >
                    Cancel Plan
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="body1" color="error">
                    No active plan. Please renew your subscription to continue.
                  </Typography>
                  <Button
                    onClick={handleReNew}
                    color="success"
                    variant="contained"
                    size="large"
                  >
                    Renew
                  </Button>
                </>
              )}
            </Box>

            <Box className="business-settings-form-billing-line"></Box>

            <Box className="business-settings-form-billing-body">
              <Box className="business-settings-form-billing-body-main">
                <Typography variant="subtitle1">Payment Method</Typography>

                {/* Map through each payment method */}
                {userData.PaymentMethod !== null &&
                Array.isArray(userData.PaymentMethod) &&
                userData.PaymentMethod.length > 0 &&
                userData.PaymentMethod[0].cardNumber !== "" ? (
                  userData.PaymentMethod.map((method, index) => (
                    <Box
                      className="business-settings-form-billing-body-main-content"
                      key={index}
                    >
                      <Box className="business-settings-form-billing-body-main-content-left">
                        <Box className="business-settings-form-billing-body-main-content-left-rectangle"></Box>
                        <Box className="business-settings-form-billing-body-main-content-left-body">
                          {/* Bind Card Holder */}
                          <Typography variant="body1">
                            {method.cardHolder}
                          </Typography>

                          <Box className="business-settings-form-billing-body-main-content-left-body-bottom">
                            {/* Bind Last 4 Digits */}
                            <Typography variant="caption">
                              •••• •••• •••• {method.last4}
                            </Typography>

                            <Box className="business-settings-form-billing-body-main-content-left-body-bottom-body">
                              {/* Bind Expiration Date */}
                              <Typography variant="caption">
                                Expiration:
                              </Typography>
                              <Typography variant="caption">
                                {method.expiryDate}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box className="business-settings-form-billing-body-main-content-right">
                        {/* Edit and Delete Buttons */}
                        <IconButton
                          className="edit-button"
                          onClick={() => handleEditPaymentMethod(index)}
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton
                          className="delete-button"
                          onClick={() => handleConfirmDelete(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body1">No Data Found</Typography>
                )}
              </Box>
            </Box>

            {/* Add Payment Method Button */}
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => handleAddPaymentMethod()}
            >
              Payment Method
            </Button>
          </Box>
        );
      case 2:
        return (
          <Box className="business-settings-form" key="thirdTab">
            <Box className="business-settings-form-team-header">
              <Typography variant="h5-serif">Account Access</Typography>
              <Typography variant="body2">
                Manage your team members and their account permissions here.
              </Typography>
            </Box>
            {teamMembers.length > 0 && teamMembers[0].email !== "" && (
              <>
                <Box className="business-settings-form-team-line"></Box>
                <Box className="business-settings-form-team-body">
                  <Box className="business-settings-form-team-body-title">
                    <Typography variant="subtitle1">Team Members</Typography>
                  </Box>
                  <Box className="business-settings-form-team-body-members-group">
                    {teamMembers.map((member) => (
                      <Box
                        key={member.id}
                        className="business-settings-form-team-body-members-group-member"
                      >
                        <Box className="business-settings-form-team-body-members-group-member-logo">
                          <img
                            src={person1} //member.logo
                            alt="person"
                            className="business-settings-form-team-body-members-group-member-logo-person"
                          />
                          <Box className="business-settings-form-team-body-members-group-member-logo-name">
                            <Typography variant="body2">
                              {member.userName}
                            </Typography>
                            <Typography variant="caption">
                              {member.email}
                            </Typography>
                          </Box>
                        </Box>
                        <FormControlLabel
                          className="business-settings-form-team-body-members-group-member-control"
                          control={
                            <Checkbox
                              checked={member.roleName === "Admin"}
                              disabled
                            />
                          }
                          label="Admin"
                        />
                        <FormControlLabel
                          className="business-settings-form-team-body-members-group-member-control"
                          control={
                            <Checkbox
                              checked={member.roleName === "Editor"}
                              disabled
                            />
                          }
                          label="Editor"
                        />
                        <FormControlLabel
                          className="business-settings-form-team-body-members-group-member-control"
                          control={
                            <Checkbox
                              checked={member.roleName === "Viewer"}
                              disabled
                            />
                          }
                          label="Viewer"
                        />
                        <IconButton
                          className="business-settings-form-team-body-members-group-member-icon"
                          onClick={() => handleRemoveAgencyDetails(member.id, member.email, member.unqiueId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </>
            )}
            <Box className="business-settings-form-team-line"></Box>
            <Box className="business-settings-form-team-invite-members">
              <Box className="business-settings-form-team-invite-members-label">
                <Typography variant="subtitle1">Invite Team Members</Typography>
              </Box>
              <Box className="business-settings-form-team-invite-members-body">
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  variant="filled"
                  size="medium"
                  value={newMemberEmail}
                  onChange={(e) => setNewMemberEmail(e.target.value)}
                  error={emailError}
                  helperText={
                    emailError ? "Please enter a valid email address" : ""
                  }
                  onBlur={validateEmail} // Validate on blur (when user leaves the input)
                />
                {/* Multi-Select Dropdown for selecting roles */}
                <Button
                  variant="text"
                  color="secondary"
                  size="medium"
                  endIcon={<ExpandMoreIcon />}
                  onClick={handleClick}
                >
                  {displaySelectedRoles()}
                </Button>
                {/* MUI Menu Dropdown */}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "role-button",
                  }}
                >
                  {roleOptions.map((role) => (
                    <MenuItem
                      key={role.id}
                      onClick={() => handleRoleChange(role.id)} // Correct: Pass role ID
                    >
                      <FormControlLabel
                        control={
                          <Radio checked={newMemberRoleId === role.id} /> // Compare IDs, not labels
                        }
                        label={role.label} // Display role label
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Box className="business-settings-form-team-invite-members-controls">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={handleAddAnotherEmail}
                >
                  Add Another
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  onClick={handleSendInvites}
                  disabled={invitedMembers.length === 0}
                >
                  Send Invites
                </Button>
              </Box>
              {invitedMembers.length > 0 && (
                <Box className="business-settings-form-team-invite-members-controls">
                  <Typography variant="subtitle2">Invited Members:</Typography>
                  <ul>
                    {invitedMembers.map((member, index) => {
                      const roleLabel = roleOptions.find(
                        (role) => role.id === member.roleId
                      )?.label;
                      return (
                        <li key={index}>
                          {member.email} - ({roleLabel})
                          <IconButton
                            aria-label="remove"
                            onClick={() => handleRemoveMember(index)}
                          >
                            <CloseIcon color="warning" />
                          </IconButton>
                        </li>
                      );
                    })}
                  </ul>
                </Box>
              )}
            </Box>
          </Box>
        );
      default:
        return <Typography variant="h6">No Content Available</Typography>;
    }
  };

  const ConnectGoogle = () => {
     window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20profile%20email https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/analytics.edit
     https://www.googleapis.com/auth/userinfo.email&prompt=consent&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${urlConstants.ENV_URL}/signin-google&flowName=GeneralOAuthFlow&client_id=${urlConstants.CLIENT_ID}`;
   };
   

  return (
    <Box
      className="business-settings-container"
      sx={{ maxWidth: tabIndex === 0 ? "64rem" : "32rem" }}
    >
      <Box className="business-settings-header">
        <Typography
          variant="h6"
          gutterBottom
          className="business-settings-header-title"
        >
          Settings
        </Typography>
        {/* Tabs for navigation */}
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          // orientation="horizontal"
          // variant="standard"
          // scrollButtons={false}
          className="business-settings-tab-container"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            key="profile-tab"
            icon={<AccountBalanceIcon />}
            label="Profile"
            className="business-settings-tab-container-tab"
          />
          {userDetails.roleid !== 2 && <Tab
            key="billing-tab"
            icon={<PaymentIcon />}
            label="Billing"
            className="business-settings-tab-container-tab"
          />}
          {userDetails.roleid !== 2 && (
            <Tab
              key="team-tab"
              icon={<GroupsIcon />}
              label="Team"
              className="business-settings-tab-container-tab"
            />
          )}
        </Tabs>
      </Box>
      {/* Render content based on selected tab */}
      <CommonModalPopUp
        open={isModalOpen}
        onClose={handleCloseModal}
        title="Are you sure you want to cancel your plan?"
        message1="You will lose access to Kanahaku when your current subscription expires on"
        message2={
          userData.UserPlan !== null ? userData.UserPlan.planExpiry : ""
        }
        standardLabel="Stay on Standard"
        cancelLabel="Cancel Plan"
        onStandard={handleStandard}
        onCancel={handleCancel}
      />
      {/* Renew Popup */}
      <ModalPopUpReNew
        open={isModalOpenReNew}
        onClose={handleExit}
        title="We're sorry to see you go!"
        message1="please renew your plan before"
        message2={
          (userData.UserPlan !== null && userData.UserPlan.nextPayment) ||
          "your end date"
        }
        message3="to continue"
        message4={`${
          (userData.UserPlan !== null && userData.UserPlan.planName) ||
          "your services"
        }`}
        reNewLabel="Renew"
        exitLabel="Close"
        onReNewAction={handleReNew}
        onExit={handleExit}
      />

      {/* Payment Modal Pop-up */}
      <PaymentModalPopUp
        userSettingsId={userData.Id}
        open={isModalOpenPayment}
        onClose={handleCloseModalPayment}
        isEditMode={isEditMode}
        paymentData={selectPaymentMethod}
      />
      {/* Confirmation Dailog */}
      <ConfirmationDialog
        open={openConfirmDialog}
        title="Are you sure you want to delete?"
        content="This action cannot be undone."
        onConfirm={handleRemovePaymentMethod}
        onCancel={() => setOpenConfirmDialog(false)}
        loading={loading}
      />
      <Loader spinning={loading}>{renderTabContent()}</Loader>
    </Box>
  );
};

export default SettingsBusinessProfile;
