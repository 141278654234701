/* eslint-disable react-hooks/exhaustive-deps */

import './EditProfile.scss';

import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../utils/loader/Loader';
import { useBusinessProfileApis } from '../../custom_hooks/useBusinessProfileApis';
import { useSelector } from 'react-redux';

//import  { useApi } from '../../custom_hooks/useApi';







type TransformedObject = {
  Name: string;
  Description: string;
  Website: string;
  ProductsServices: string[];
  LocationType: string;
  Locations: string[];
  CustomerPersonas: { personName: string; personDescription: string }[] | null;
  SellingPoints: string[] | null;
  BusinessObjective: string | null;
  Competitors: { name: string; website: string }[] | null;
  UserUniqueId: string;
  UniqueId: string;
};

type UpdateTransformedObject = {
  name: string;
  description: string;
  website: string;
  productsServices: string[];
  locationType: string;
  locations: string[];
  customerPersonas: { personName: string; personDescription: string }[] | null;
  sellingPoints: string[] | null;
  businessObjective: string | null;
  competitors: { name: string; website: string }[] | null;
  UserUniqueId: string;
  uniqueId: string;
};
const EditBusinessProfile: React.FC = () => {

  const navigate = useNavigate(); 
  const location = useLocation();
  const { GetBusinessProfilesDataByUniqueID, UpdateBusinessProfilesByUniqueID, businessProfilesDataLoading } = useBusinessProfileApis();
  
  const { businessData } = location.state || {}; // Get the passed business data

  const businessRef = useRef(true);
  useEffect(() => {
    if(businessRef.current){
      GetBusinessProfilesDataByUniqueID(businessData.UniqueId);
      businessRef.current = false;
    }
  }, [businessData]);

  const profileData = useSelector((state: any) => state.businessDetails.profileDataByUniqueID); // Get profileDataByUniqueID from Redux store

  const [updateProfileData, setUpdateProfileData] = useState<TransformedObject>(profileData);

  // const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files && event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setSelectedImage(reader.result as string);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };


  // const handleLocationUpdate = async () => {
  //   try {
  
  //     const values : TransformedObject  =  {
  //       ...profileData
  //     }

  //     const updateBusinessConfig: IAPIConfig = {
  //       URL: `/Profile/UpdateLocation?uniqueId=${profileData.uniqueId}`,
  //       method: "POST",
  //       payLoad: values,
  //     };
  //     const res : any =  await doApi(updateBusinessConfig, "seedcore");
  //     if (res.status === 200) {
  //       // navigate("/business-profile")
        
  //       GetBusinessProfilesData();
        
  //       CustomToast("Profile updated successfully", "success");
  //     } else {
  //       CustomToast(res.data.message, "error");
  //     }
  //   } catch (error: any) {
  //     CustomToast(error.data.message, "error");
  //   }
  // };

  const toCamelCase = (str: string) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };
  
  const convertKeysToCamelCase = (data: TransformedObject): UpdateTransformedObject => {
    const transformedObject: any = {};
  
    Object.keys(data).forEach((key) => {
      const camelCaseKey = toCamelCase(key);
      transformedObject[camelCaseKey] = data[key as keyof TransformedObject];
    });
  
    return transformedObject as UpdateTransformedObject;
  };

  const handleBack = async () => {
    // handleLocationUpdate();
    UpdateBusinessProfilesByUniqueID(businessData?.UniqueId, convertKeysToCamelCase(updateProfileData));
    navigate(-1);
  };

  return (
    <Loader spinning={businessProfilesDataLoading}>
      <div className="edit-profile">
        <ArrowBackIcon sx={{marginBottom: '12px', color: 'rgba(0, 0, 0, 0.56)', cursor: 'pointer' }} onClick={() => handleBack()} />
        {/* Business Info Section */}
        <div className="business-info section">
          <Typography variant='h5'>Edit Profile</Typography>
          <Typography variant="body1">Business Info</Typography>
          <Box sx={{display: 'flex', gap: '1rem'}} >
              {/* Business Name */}
              <TextField
                label="Business Name"
                variant="filled"
                fullWidth
                value={updateProfileData?.Name}
                onChange={(e) => setUpdateProfileData({ ...updateProfileData, Name: e.target.value })}
                sx={{ marginBottom: 2 }} // Add some space between fields
              />

              {/* Business Website */}
              <TextField
                label="Business Website"
                variant="filled"
                fullWidth
                value={updateProfileData?.Website}
                onChange={(e) => setUpdateProfileData({ ...updateProfileData, Website: e.target.value })}
                sx={{ marginBottom: 2 }} // Add some space between fields
              />
          </Box>

          {/* Business Description */}
          <TextField
            label="Business Description"
            variant="filled"
            fullWidth
            multiline
            rows={4} // Adjust the number of rows to control height
            value={updateProfileData?.Description}
            onChange={(e) => setUpdateProfileData({ ...updateProfileData, Description: e.target.value })}
          />
        </div>


        {/* Business Locations Section */}
        <div className="locations section">
          <h3>Business Location(s)</h3>

          {/* Location List */}
          {[...Array(2)].map((_, index) => (
            <div key={index} className="location-item">
              <div className="location-details">
                <Box sx={{
                      width: '60px',
                      height: '60px',
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      borderRadius: '4px',
                      }}>
                  </Box>
                <Box>
                  <h4>Business Address</h4>
                </Box>
                <Box>
                  <p>Contact Info</p>
                </Box>
              </div>

              <div className="actions">
                <IconButton>
                  <EditIcon className='icon' />
                </IconButton>
                <IconButton>
                  <DeleteIcon className='icon' />
                </IconButton>
              </div>
            </div>
          ))}

          {/* Add Location Button */}
          <Box className="add-location-button" >
              <Button
              variant="outlined"
              startIcon={<AddIcon />}
              className="add-location"
              >
              New Location
              </Button>

              {/* <Button
              variant="contained"
              className="add-location"
              onClick ={handleProfileUpdate}
              >
                Update
              </Button> */}
          </Box> 


        </div>
      </div>
    </Loader>
  );
};

export default EditBusinessProfile;
