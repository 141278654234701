import "./CustomDashboardAreaCharts.scss";

import { ApexOptions } from "apexcharts";
import { Box } from "@mui/material";
import Chart from "react-apexcharts";
import { useState } from "react";

// Interface to define the structure of trend data
interface TrendDetail {
  Total: string; // The Total is stored as a string (e.g., "403")
  Diff: string;  // The difference percentage as a string (e.g., "-25.00%")
  Trend: string; // The trend direction as a string (e.g., "down")
}

interface TrendData {
  [key: string]: TrendDetail;
}

interface CustomAreaChartProps {
  key?: any;
  loading?: boolean;
  chartType?: "area" | "line" | "bar";
  colors?: string[];
  categories: string[];
  seriesData: { name: string; data: number[] }[];
  yAxisLabels?: string[];
  title?: string;
  tooltipFormatter?: (value: any) => string;
  overViews?: TrendData;
}

const CustomDashboardAreaCharts: React.FC<CustomAreaChartProps> = ({
  chartType = "area",
  colors = ["#007bff", "#6f42c1", "#28a745", "#fd7e14"],
  seriesData,
  categories
}) => {

   // Define the series names to include in yAxisConfig
   const includedSeries = [
    "Clicks",
    "Impressions",
    "Interactions",
    "Sessions",
    "Conversions",
  ];

  const formatYAxisValue = (value: number) => {
    if (value >= 1000000) {
      // Format in millions
      return `${(value / 1000000).toFixed(1)}M`; // Round to one decimal place
    } else if (value >= 1000) {
      // Format in thousands
      return `${(value / 1000).toFixed(1)}k`; // Round to one decimal place
    }
    return value.toString(); // For values less than 1000, return as is
  };

   // Filter filteredSeries to include only objects where the name is CLK or IMP
   const yAxisConfig = seriesData
   .filter((series: { name: string; data: number[] }) =>
     includedSeries.includes(series.name)
   )
   .map((series: any, index: number) => ({
     min: 0,
     opposite: index % 2 !== 0,
     tickAmount: 5,
     labels: {
       formatter: (val: any)=> formatYAxisValue(val),
       style: { colors: "#8e8da4" },
       show: false
     },
   }));
  
  const  [options] = useState<ApexOptions>({
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      },
      zoom: {
        enabled: false
      },
      animations: { enabled: false }
    },
    colors,
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      curve: 'straight',
      width: 2
    },
    legend: {
      show: false
    },
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      type: "datetime",
      categories,
    },
    yaxis: yAxisConfig
  });
  
  
  return (
    <Box  sx={{ width: "100%" }} >
      <Chart
            key={JSON.stringify(seriesData)} 
            options={options}
            series={seriesData}
            type={chartType}
            height={96}
            width = "100%" 
          />
    </Box>
  );
};

export default CustomDashboardAreaCharts;
