import React, { useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import countries from "../countries.json";

type Country = {
  name: string;
  flag: string;
  code: string;
  dial_code: string;
};

const CountryAutocomplete: React.FC<{ onCountrySelect: (country: Country | null) => void }> = ({ onCountrySelect }) => {
  const defaultCountry = countries.find((country) => country.code === "US");
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(defaultCountry || null);

  useEffect(() => {
    if (defaultCountry) {
      onCountrySelect(defaultCountry); 
    }
  }, [defaultCountry, onCountrySelect]);

  return (
    <Autocomplete
      options={countries}
      value={selectedCountry}
      getOptionLabel={(option) => `${option.name} (${option.dial_code})`}
      renderOption={(props, option) => (
        <li {...props} style={{ whiteSpace: "nowrap" }}>
          {option.name} ({option.dial_code})
        </li>
      )}
      onChange={(_, value: Country | null) => {
        setSelectedCountry(value);
        onCountrySelect(value);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Choose a country" variant="outlined" />
      )}
      sx={{
        "& .MuiAutocomplete-listbox": {
          maxWidth: "none",
          minWidth: "200px",
        },
        "& .MuiAutocomplete-inputRoot": {
          minWidth: "190px",
        },
      }}
    />
  );
};

export default CountryAutocomplete;
