import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

type Business = {
  Title: string;
  WebsiteUri: string;
  Locations: Array<{ Name: string }>;
  propertyIds: Array<{ DisplayName: string; propertyId: string }>;
  DisplayName: string;
};

type ModalBusinessProps = {
  visible: boolean;
  validBusiness: Business[];
  onCancel: () => void;
  onSubmit: (selectedBusinesses: Array<{ propertyId: string; url: string }>) => void;
};

const ModalPopUpForProperties: React.FC<ModalBusinessProps> = ({
  visible,
  validBusiness,
  onCancel,
  onSubmit,
}) => {
  const [selectedProperties, setSelectedProperties] = useState<Map<string, { propertyId: string; url: string }>>(new Map());
  const [scrollDirection, setScrollDirection] = useState<'down' | 'up'>('down');
  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const element = contentRef.current;
    if (element) {
      const { scrollHeight, scrollTop, clientHeight } = element;
      console.log(`scrollTop: ${scrollTop}, clientHeight: ${clientHeight}, scrollHeight: ${scrollHeight}`);
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setScrollDirection('up'); 
      } else if (scrollTop === 0) {
        setScrollDirection('down'); 
      }
    }
  };

  const handleScrollClick = () => {
    const element = contentRef.current;
    if (element) {
      if (scrollDirection === 'down') {
        element.scrollBy({
          top: 150,
          behavior: "smooth",
        });
      } else {
        element.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    setScrollDirection('down');
  }, [visible]);


  const handleRadioChange = (businessId: string, propertyId: string, WebsiteUri: string) => {
    setSelectedProperties((prev) => new Map(prev).set(businessId, { propertyId, url: WebsiteUri }));
  };

  const handleSubmit = () => {
    const selectedBusinessArray = Array.from(selectedProperties.values());
    if (selectedBusinessArray.length > 0) {
      onSubmit(selectedBusinessArray);
    }
  };

  return (
    <Dialog open={visible}  onClose={(e, reason) => {
      if (reason === "backdropClick") {
        return;
      }
      onCancel();
    }}fullWidth maxWidth="md">
      <DialogTitle>Select Business</DialogTitle>
      <DialogContent>
        <Box
          ref={contentRef}
          onScroll={handleScroll}
          sx={{ maxHeight: "400px", overflowY: "auto", position: "relative" }}
        >
          <Grid container spacing={3} justifyContent="center">
            {validBusiness.map((business, index) => (
              <Grid
                item
                xs={12}
                sm={validBusiness.length === 2 ? 6 : 4}
                md={validBusiness.length === 2 ? 6 : 4}
                key={index}
              >
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                      {business.DisplayName}
                    </Typography>
                    <Grid container spacing={2}>
                      {business.propertyIds.map((property) => (
                        <Grid item xs={12} key={property.propertyId}>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  selectedProperties.get(business.DisplayName)?.propertyId ===
                                  property.propertyId
                                }
                                onChange={() =>
                                  handleRadioChange(
                                    business.DisplayName,
                                    property.propertyId,
                                    business.WebsiteUri
                                  )
                                }
                              />
                            }
                            label={property.DisplayName}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        {validBusiness.length > 0 && (
          <Box
            onClick={handleScrollClick}
            sx={{
              cursor: "pointer",
              position: "absolute",
              bottom: "8px",
              left: "16px",
              background: "rgba(255, 255, 255, 0.9)",
              borderRadius: "50%",
              p: 1,
              boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            {scrollDirection === 'down' ? (
              <ArrowDownwardIcon fontSize="large" />
            ) : (
              <ArrowUpwardIcon fontSize="large" />
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="large" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          size="large"
          disabled={selectedProperties.size === 0}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalPopUpForProperties;
