import CustomToast from "../utils/cutomToast/CustomToast";
import { urlConstants } from "../components/constants/globalConstants";
import { useApi } from "./useApi";
import useSendPushNotifications from "./useSendPushNotifications";
import { useState } from "react";

interface EmailPayload {
  from: string;
  to: string;
  subject: string;
  cc: string;
  textbody: string;
  htmlbody: string;
}

interface IAPIConfig {
  URL: string;
  method: string;
  payLoad: any;
}

const useSendEmail = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { doApi } = useApi();
  const { sendPushNotifications } = useSendPushNotifications();
  const sendEmailInvitation = async (email: string, url: string) => {
    setLoading(true);
    setError(null);

    const emailPayload: EmailPayload = {
      from: urlConstants.SENDER_EMAIL_ID,
      to: email,
      subject: "You're Invited! Please Verify Your Email",
      cc: "",
      textbody: `Dear User,\n\nPlease click the link below to verify your email and complete your signup.\n\n${url}`,
      htmlbody: `
        <p>Dear User,</p>
        <p>Please click the button below to verify your email and complete your signup:</p>
        <a href="${url}" target="_blank" style="text-decoration:none;">
          <button style="
            background-color: #4CAF50;
            color: white;
            padding: 10px 20px;
            text-align: center;
            font-size: 16px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          ">Complete Signup</button>
        </a>
      `,
    };

    const sendEmailConfig: IAPIConfig = {
      URL: `/Email/SendMail`,
      method: "POST",
      payLoad: emailPayload,
    };

    try {
      const emailRes: any = await doApi(sendEmailConfig, "seedcore");
      if (emailRes.data.err === false) {
        sendPushNotifications({
          title: `Invite sent to`,
          body: `${email}`,
        });
      } else {
        throw new Error(`Failed to send email to ${email}`);
      }
    } catch (err: any) {
      setError(err.message || "An unexpected error occurred");
      CustomToast(`Failed to send email to ${email}`, "error");
    } finally {
      setLoading(false);
    }
  };

  return { sendEmailInvitation, loading, error };
};

export default useSendEmail;
