import './CreateBusinessProfile.scss'

import { Avatar, Box, Button, CardContent, CircularProgress, TextField, Typography } from '@mui/material'
import  { IAPIConfig, useApi } from '../../../custom_hooks/useApi';
import React, { useEffect, useState } from 'react'
import { fetchBusinessProfilesSuccess, setExpandedBusiness } from '../../../store/businessSlice';

import BusinessConfirmationModalProps from './BusinessConfirmationModalPopUp';
import BusinessModalPopUp from './BusinessModalPopUp';
import CustomToast from '../../../utils/cutomToast/CustomToast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSendPushNotifications from '../../../custom_hooks/useSendPushNotifications';

interface FormValues {
  businessName: string;
  businessWebsite: string;
}

interface FormErrors {
  businessName?: string;
  businessWebsite?: string;
}

type TransformedObject = {
  name: string;
  description: string;
  website: string;
  productsServices: string[];
  locationType: string;
  locations: string[];
  customerPersonas?: { personName: string; personDescription: string }[] | null;
  sellingPoints?: string[] | null;
  businessObjective?: string | null;
  competitors?: { name: string; website: string }[] | null;
  UserUniqueId: string;
  uniqueId?: string;
};

const CreateBusinessProfile = () => {

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const { doApi } = useApi();
  const { sendPushNotifications } = useSendPushNotifications();


  // const handleClosePreview = () => {
  //  setOpen(false);
  // };

  useEffect(()=>{
    setOpen(true);
  },[])

  const [formValues, setFormValues] = useState<FormValues>({
    businessName: '',
    businessWebsite: '',
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({
    businessName: '',
    businessWebsite: '',
  });

  const [loading, setLoading] = useState(false);
  const [formBusinessStep, setBusinessStep] = useState(0);

  const validateForm = (): boolean => {
    const errors: FormErrors = {};

    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

    if (!formValues.businessName.trim()) {
      errors.businessName = 'Business Name is required';
    }

    if (!formValues.businessWebsite.trim()) {
      errors.businessWebsite = 'Business Website is required';
    } else if (!urlPattern.test(formValues.businessWebsite)) {
      errors.businessWebsite = 'Make sure your URL is valid';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    setBusinessStep(1);
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   setBusinessStep(1);
    //   console.log('Form submitted:', formValues);
    // }, 2000);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // Capitalize the first letter and keep the rest unchanged
    const newValue = name === "businessName" 
    ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() 
    : value;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));

     // Clear the error for the specific field that is being changed
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '', // Clear the error for the current field
      }));
  };

 const [businessConfirmationOpen, setBusinessConfirmationOpen] = useState(false);

  const handleBusinessClosePreview = () => {
    // setBusinessConfirmationOpen(false);
    navigate("/home")
  };

  const dispatch = useDispatch();


  const GetBusinessProfilesData = async () => {
    try {
      const userDetails = JSON.parse(sessionStorage.getItem("user") || "{}");
      const fetchBusinessProfilesConfig: IAPIConfig = {
        URL: `/Profile/GetUserBusinessProfiles/?useruniqueId=${userDetails?.uniqueId}`,
        method: "GET",
      };
      const res: any = await doApi(fetchBusinessProfilesConfig, "seedcore");
      if (!res.data.err) {
        dispatch(fetchBusinessProfilesSuccess(res.data));
      } else {
        CustomToast(res.data.data.message, "error");
        console.log("response", res);
        
      }
    } catch (error: any) {
      CustomToast(error.message, "error");
    }
  };




  const handleCompleteBusiness = async() => {
    
    try {
    setLoading(true);

      let companyProfileConfig: IAPIConfig;
      const userDetails = JSON.parse(sessionStorage.getItem("user") || "{}");
      const values : TransformedObject  =  {
        UserUniqueId: userDetails?.uniqueId || "",
        name: formValues.businessName || "",
        website: formValues.businessWebsite || "",
        description: "",
        productsServices: [],
        locationType: "",
        locations: [],
        customerPersonas: null,
        sellingPoints: null,
        businessObjective: "",
        competitors: null,
        uniqueId: ""
      }

      companyProfileConfig = {
        URL: "/Profile/CreateBusinessProfile",
        method: "POST",
        payLoad: values,
      };
      
      const res: any = await doApi(companyProfileConfig, "seedcore");
      // console.log("handleCompleteBusiness ~ res:", res.response.data.message);
      if (res.status === 200) {
        sendPushNotifications({
           title: values.name,
           body: `Business Profile created successfully`
        });
        GetBusinessProfilesData();
        dispatch(setExpandedBusiness(values.name));

        const businessData ={
          Name: values.name || "",
          Website: values.website || "", 
        }
        navigate('/dashboard', { state: { businessData }});
        setBusinessConfirmationOpen(true);
        setOpen(false);
        
      } else {
        CustomToast(`${res.response.data.message ? res.response.data.message : "Business Profile not created successfully"}`, "error");
        
      }
    } catch (error: any) {
      CustomToast(error.message || "An error occurred", "error");

    }finally {
      setLoading(false);
    } 
  };

  return (
    <>

    {/* <Button variant="contained" onClick={() => setOpen(true)} >Add Business</Button> */}

     <BusinessModalPopUp open={open} title="Add a New Business" description='Modal description goes here and it is optional' width='100%' >
      {
        formBusinessStep === 0 && (
          <>
          <Box>
        <TextField
          label="Business Name"
          name="businessName"
          variant="filled"
          size="medium"
          fullWidth
          value={formValues.businessName}
          onChange={handleChange}
          error={!!formErrors.businessName}
          helperText={formErrors.businessName}
        />
          </Box>

          <Box>
            <TextField
              label="Business Website"
              name="businessWebsite"
              variant="filled"
              size="medium"
              fullWidth
              value={formValues.businessWebsite}
              onChange={handleChange}
              error={!!formErrors.businessWebsite}
              helperText={formErrors.businessWebsite}
            />
          </Box>

          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: 2, alignSelf: 'flex-start' }}
          >
      
            Continue
            
          </Button>
          </>
        )
      }
     {
      formBusinessStep === 1 && (
        <Box >
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        {/* Placeholder for the Logo or Avatar */}
        <Avatar sx={{ bgcolor: '#e0e0e0', width: 40, height: 40, marginRight: 2 }}>
          {/* You can replace this with an actual image */}
        </Avatar>

        {/* Business Info */}
        <CardContent sx={{ padding: '0px' }}>
          {/* Business Name */}
          <Typography variant="h6" fontWeight="bold">
            {formValues?.businessName}
          </Typography>

          {/* Locations */}
          {/* <Typography variant="body2" color="textSecondary">
            Locations: <Box component="span" sx={{fontWeight: 'bold'}} >4</Box>
          </Typography>  */}

          {/* Website */}
          <Typography variant="body2" color="textSecondary"  >
            Website: <Box component="span" sx={{fontWeight: 'bold'}} >{formValues.businessWebsite}</Box>
          </Typography>
        </CardContent>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }} >
          <Button
            variant="outlined"
            onClick={()=>setBusinessStep(0)}
            sx={{ mt: 2, alignSelf: 'flex-start' }}
            disabled={loading}
          >
            Make Changes
          </Button>
          <Button
            variant="contained"
            onClick={handleCompleteBusiness}
            sx={{ mt: 2, alignSelf: 'flex-start' }}
            disabled={loading}
            
          >
           
           {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              'Confirm'
            )}
          
          </Button>
        </Box>
        </Box>
      )
     }
        
    </BusinessModalPopUp>  

  
    <BusinessConfirmationModalProps open={businessConfirmationOpen} onClose={handleBusinessClosePreview} >

      <Box >
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        {/* Placeholder for the Logo or Avatar */}
        <Avatar sx={{ bgcolor: '#e0e0e0', width: 40, height: 40, marginRight: 2 }}>
          {/* You can replace this with an actual image */}
        </Avatar>

        {/* Business Info */}
        <CardContent sx={{ padding: '0px' }}>
          {/* Business Name */}
          <Typography variant="h6" fontWeight="bold">
            {formValues?.businessName}
          </Typography>

          {/* Locations */}
           {/* <Typography variant="body2" color="textSecondary">
            Locations: <Box component="span" sx={{fontWeight: 'bold'}} >4</Box>
          </Typography>  */}

          {/* Website */}
          <Typography variant="body2" color="textSecondary"  >
            Website: <Box component="span" sx={{fontWeight: 'bold'}} >{formValues?.businessWebsite}</Box>
          </Typography>
        </CardContent>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }} >
          <Button
            variant="outlined"
            onClick={handleBusinessClosePreview}
            sx={{ mt: 2, alignSelf: 'flex-start' }}
          >
            Done
          </Button>
          <Button
            variant="contained"
            onClick={()=>navigate("/home")}
            sx={{ mt: 2, alignSelf: 'flex-start' }}
          >
            Complete Business Profile
          </Button>
        </Box>
      </Box>
    </BusinessConfirmationModalProps>  

    
    </>
  )
}

export default CreateBusinessProfile