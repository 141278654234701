import { Box, Button, Container, Typography } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";
import { useNavigate } from "react-router-dom";

const TermsConditions: React.FC = () => {
  const navigate = useNavigate();

  // Back button functionality
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="md">
      <Box mb={4} mt={4}>
        <Button
          variant="outlined"
          onClick={handleBack}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Last Updated: January 17, 2025
        </Typography>

        <Typography variant="body1" paragraph>
          Welcome and thank you for your interest in Massic, a service operated
          and owned by Kanahiku LLC ("Massic", "we", "us", or "our"). This Terms
          of Service Agreement ("Terms of Service" or "Agreement") describes the
          terms and conditions that apply to your use of (i) any Massic websites
          and their subdomains (collectively, the "Website"), (ii) any mobile
          applications that we offer (each, an "Application"), and (iii) the
          products, services, content, and other resources available or enabled
          via our Website or any Application, including our B2B
          software-as-a-service platform for organic marketing analysis, data
          storage, and marketing optimization tools, integrations, APIs, and
          documentation (collectively, with our Applications and Website, the
          "Service").
        </Typography>

        <Typography variant="body1" paragraph>
          PLEASE READ THIS AGREEMENT CAREFULLY. BY ACCESSING OR USING THE
          SERVICE IN ANY WAY, ACCEPTING THIS AGREEMENT, COMPLETING THE ACCOUNT
          REGISTRATION PROCESS, OR BROWSING THE WEBSITE, YOU REPRESENT THAT: (1)
          YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THIS AGREEMENT,
          (2) YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH MASSIC, (3)
          YOU ARE NOT BARRED FROM USING THE SERVICE UNDER THE LAWS OF THE UNITED
          STATES, YOUR PLACE OF RESIDENCE OR ANY OTHER APPLICABLE JURISDICTION,
          AND (4) YOU HAVE THE AUTHORITY TO ENTER INTO THIS AGREEMENT PERSONALLY
          OR ON BEHALF OF THE ENTITY YOU REPRESENT. IF YOU DO NOT AGREE TO BE
          BOUND BY THESE TERMS, YOU MAY NOT ACCESS OR USE THE SERVICE.
        </Typography>

        <Typography variant="body1" paragraph>
          IF YOU SUBSCRIBE TO THE SERVICE FOR A TERM (THE "INITIAL TERM"), THEN
          YOUR SUBSCRIPTION WILL BE AUTOMATICALLY RENEWED FOR ADDITIONAL PERIODS
          OF THE SAME DURATION AS THE INITIAL TERM AT MASSIC'S THEN-CURRENT FEE
          UNLESS YOU DECLINE TO RENEW YOUR SUBSCRIPTION IN ACCORDANCE WITH
          SECTION 7 (SUBSCRIPTION AND BILLING) BELOW.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          1. Use of the Service
        </Typography>
        <Typography variant="body1" paragraph>
          The Service and the information and content available through the
          Service are protected by copyright and other intellectual property
          laws. Unless subject to a separate license agreement between you and
          Massic, your right to access and use the Service is subject to this
          Agreement.
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          1.1 Application License
        </Typography>
        <Typography variant="body1" paragraph>
          Subject to your compliance with this Agreement, Massic grants you a
          limited non-exclusive, non-transferable, non-sublicensable, revocable
          license to download, install and use the Applications on devices that
          you own or control solely for your internal business purposes.
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          1.2 Service Updates
        </Typography>
        <Typography variant="body1" paragraph>
          You understand that the Service is evolving. Massic may update the
          Service with or without notifying you. You may need to update
          third-party software from time to time to continue using the Service.
          Any future release, update, or addition to the Service shall be
          subject to this Agreement.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          2. Account Registration and Security
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          2.1 Registration Requirements
        </Typography>
        <Typography variant="body1" paragraph>
          To access certain features of the Service, you must register for an
          account ("Account"). When registering for and maintaining an Account,
          you must provide accurate, current, and complete information. You are
          responsible for maintaining the security of your Account credentials
          and for any activity that occurs under your Account.
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          2.2 Enterprise Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          For enterprise customers, we offer workspace collaboration features.
          The initial registered user who creates an enterprise Account will be
          designated as the administrator ("Admin") and will have the ability to
          create and manage user accounts within their workspace. The Admin is
          responsible for all activities within their workspace and managing
          user access rights.
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          2.3 Account Restrictions
        </Typography>
        <Typography variant="body1" paragraph>
          You agree not to:
          <ul>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Share your Account or password with anyone</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Create an Account using false information</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Create multiple Accounts</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Access the Service if previously removed or banned</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>
              Create an Account on behalf of another person without
              authorization
            </li>
          </ul>
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          3. Data Processing and Third-Party Services
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          3.1 Connected Services
        </Typography>
        <Typography variant="body1" paragraph>
          The Service may integrate with third-party services and platforms
          ("Connected Services"). Your use of such Connected Services is subject
          to their respective terms of service. Massic is not responsible for
          the availability, accuracy, or security of Connected Services.
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          3.2 Artificial Intelligence Processing
        </Typography>
        <Typography variant="body1" paragraph>
          The Service uses artificial intelligence and machine learning
          technologies ("AI Technologies") to analyze data and provide insights.
          By using the Service, you acknowledge and agree that:
          <ul>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Your content will be processed by AI Technologies</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>
              We implement appropriate technical safeguards for AI processing
            </li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>
              We do not use your data to train our AI models without explicit
              consent
            </li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>
              You retain ownership of your content and any outputs generated
              from it
            </li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>
              We maintain security measures to protect data processed by AI
              Technologies
            </li>
          </ul>
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          4. User Content and Data Rights
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          4.1 Your Content
        </Typography>
        <Typography variant="body1" paragraph>
          You retain all rights to data and content you submit to the Service
          ("Your Content"). By using the Service, you grant Massic a worldwide,
          non-exclusive, royalty-free license to host, store, transfer, display,
          perform, reproduce, modify, and distribute Your Content solely for
          purposes of providing and improving the Service.
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          4.2 Data Storage and Security
        </Typography>
        <Typography variant="body1" paragraph>
          While we implement reasonable security measures, we do not guarantee
          the security or availability of Your Content. You are responsible for
          maintaining backups of Your Content. We may establish reasonable
          limits on storage space and data retention periods.
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          4.3 Usage Data
        </Typography>
        <Typography variant="body1" paragraph>
          We may collect and use data about your use of the Service, including
          analytics, logs, and aggregated data, to operate and improve our
          services. Such usage data is owned by Massic.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          5. Intellectual Property Rights
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          5.1 Massic Property
        </Typography>
        <Typography variant="body1" paragraph>
          The Service, including all software, algorithms, designs, graphics,
          and interfaces, is owned by Massic and protected by intellectual
          property laws. Nothing in this Agreement transfers any ownership
          rights to you.
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          5.2 Feedback
        </Typography>
        <Typography variant="body1" paragraph>
          If you provide suggestions or feedback about the Service, you grant
          Massic a perpetual, irrevocable, worldwide license to use such
          feedback without compensation or attribution.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          6. User Conduct
        </Typography>
        <Typography variant="body1" paragraph>
          You agree not to:
          <ul>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Use the Service for any illegal purpose</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Interfere with the operation of the Service</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Attempt to gain unauthorized access</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Upload malicious content or viruses</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Violate any third-party rights</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Engage in unauthorized automated access</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Use the Service to develop competing products</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>Reverse engineer the Service</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }} >Remove proprietary notices or labels</li>
          </ul>
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          7. Subscription and Billing
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          7.1 Fees and Payment
        </Typography>
        <Typography variant="body1" paragraph>
          You shall pay all fees in accordance with the terms specified when you
          subscribe. Fees are non-refundable except as required by law. We use
          third-party payment processors and you agree to their terms of
          service.
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          7.2 Subscription Terms
        </Typography>
        <Typography variant="body1" paragraph>
          Subscriptions automatically renew for the same duration. You may
          cancel renewal through your account settings. Price changes will be
          notified in advance. No refunds for partial subscription periods.
          Enterprise pricing available upon request.
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          7.3 Taxes
        </Typography>
        <Typography variant="body1" paragraph>
          Fees are exclusive of taxes. You are responsible for all applicable
          taxes, including sales tax and withholding tax.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          8. Term and Termination
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          8.1 Term
        </Typography>
        <Typography variant="body1" paragraph>
          This Agreement remains in effect until terminated by either party.
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          8.2 Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We may terminate or suspend your access:
          <ul>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>For breach of these Terms</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>For non-payment</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>If required by law</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>At our reasonable discretion with notice</li>
          </ul>
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          8.3 Effect of Termination
        </Typography>
        <Typography variant="body1" paragraph>
          Upon termination:
          <ul>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>All rights and licenses terminate</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>You must cease using the Service</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>You may export your data within 30 days</li>
            <li style={{listStyleType: 'disc', marginLeft: '20px' }}>We may delete your data after 90 days</li>
          </ul>
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          9. Warranties and Disclaimers
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          9.1 Limited Warranty
        </Typography>
        <Typography variant="body1" paragraph>
          We warrant that the Service will perform substantially as described
          under normal use.
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          9.2 Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, THE SERVICE IS
          PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          10. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          In no event shall Massic be liable for any indirect, incidental,
          special, consequential, or punitive damages, or any loss of profits,
          revenue, or data, arising out of or in connection with this Agreement,
          even if advised of the possibility of such damages.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          11. Indemnification
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to indemnify, defend, and hold Massic harmless from any
          claims, damages, liabilities, costs, or expenses arising from your use
          of the Service or your breach of this Agreement.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          12. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          This Agreement shall be governed by and construed in accordance with
          the laws of the State of California, without regard to its conflict of
          law principles. Any legal action or proceeding related to this
          Agreement shall be brought exclusively in the courts located in San
          Francisco, California.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          13. Miscellaneous
        </Typography>
        <Typography variant="body1" paragraph>
          This Agreement constitutes the entire agreement between you and Massic
          regarding the Service. If any provision is found to be unenforceable,
          the remainder will continue in full force and effect. No waiver of any
          provision of this Agreement will be deemed a waiver of any other
          provision.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsConditions;
