import { IAPIConfig, useApi } from './useApi';

import { useState } from 'react';

const useImageUpload = (userDetails: { id: string }) => {
  const { doApi } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const uploadImage = async (base64String: string, fileName?: string): Promise<string> => {
    if (!base64String) {
      console.error("No Base64 string provided.");
      return "";
    }

    // Prepare form data for the API request
    const formData: any = new FormData();

    // Extract the Base64 data (remove the prefix like 'data:image/png;base64,' if it exists)
    const base64Data = base64String.split(',')[1]; // Get the part after the comma

    // Create a Blob from the Base64 string (you can adjust the MIME type accordingly)
    const byteCharacters = atob(base64Data); // Decode base64 string to binary data
    const byteArrays = [];

    // Convert the byte characters to byte arrays
    for (let offset = 0; offset < byteCharacters.length; offset++) {
      byteArrays.push(byteCharacters.charCodeAt(offset));
    }

    // Create a Blob from the byte array
    const blob = new Blob([new Uint8Array(byteArrays)], { type: "image/png" });

    // Append the Blob to the FormData (this mimics the working `FileReader` method)
    formData.append("UploadFile", blob, fileName);  // You can adjust the file name and type

    try {
      setLoading(true);
      setError(null); // Reset error state before the upload attempt

      const logoUploadConfig: IAPIConfig = {
        URL: `/Storage/UploadToBlob?Container=usr&Entity=logo&ItemId=${userDetails.id}`,
        method: "POST",
        payLoad: formData,
      };

      // Make the API call to upload the Blob data
      const res: any = await doApi(logoUploadConfig, "seedcore");

      if (res.data.err === false) {
        return res.data.uri; 
      } else {
        return "";  
      }
    } catch (err: any) {
      setError(err.message);  
      return "";  
    } finally {
      setLoading(false);
    }
  };

  return { uploadImage, loading, error };
};

export default useImageUpload;
