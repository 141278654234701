const firebaseConfig = {
   apiKey: "AIzaSyD1-BivDRXHpGx5pcpA1nQCZcpR0J7FEfg",
    authDomain: "massicio-4b2de.firebaseapp.com",
    projectId: "massicio-4b2de",
    storageBucket: "massicio-4b2de.firebasestorage.app",
    messagingSenderId: "618766537885",
    appId: "1:618766537885:web:704dfa6e1b1d542437f074",
    measurementId: "G-TD3REX4H59"
};

export default firebaseConfig;
