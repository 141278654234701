/* eslint-disable react-hooks/exhaustive-deps */

import "./CreateUser.scss";

import * as Yup from "yup";

import { Form as AntForm, Button, Input, Select } from "antd";
import { Field, FieldProps, Form, Formik } from "formik";
import  { IAPIConfig, useApi } from "../../../custom_hooks/useApi";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import CustomToast from "../../../utils/cutomToast/CustomToast";
import Loader from "../../../utils/loader/Loader";
import { urlConstants } from "../../../components/constants/globalConstants";
import useSendEmail from "../../../custom_hooks/sendEmail";
import useSendPushNotifications from "../../../custom_hooks/useSendPushNotifications";
import { userProfile } from "../../../store/userProfileSlice";

const { Option } = Select;

interface CreateUserPageProps {
  update: boolean;
  editable: boolean;
}

type FormValues = {
  RoleId: Number;
  FirstName: string;
  LastName: string;
  Email: string;
  // Password: string;
  // ConfirmPassword: string;
  // MobileNo: string;
  Id: Number;
};

const CreateUser: React.FC<CreateUserPageProps> = ({
  update = false,
  editable = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { formData } = useSelector((state: any) => state.userProfileDetails);
  const { sendPushNotifications } = useSendPushNotifications();
  const [userDetails, setUserDetails] = useState({
    CreatedBy: -1,
    Email: "",
    OrganzationName: "",
    Entity: "",
    RoleId: 3,
    TeamMemberRoleId: -1,
  });
  const { doApi } = useApi();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const firstTime = useRef(true);
  const { sendEmailInvitation } = useSendEmail();
  const pathname = location.pathname;
  
  const validateToken = async (token: string) => {
    setLoading(true);
    try {
      const decryptConfig: IAPIConfig = {
        URL: "/decrypt",
        method: "POST",
        payLoad: { decryptedText: token },
      };

      const encryptRes: any = await doApi(decryptConfig, "seedmain");
      const decryptEmail = encryptRes.data.data;

      const tokenConfig: IAPIConfig = {
        URL: `/validate-invite-token?token=${token}&email=${decryptEmail}`,
        method: "GET",
      };

      const res: any = await doApi(tokenConfig, "seedmain");
      if (!res.response && res.data.success === true) {
         CustomToast("Email validated . Please complete your profile.", "success");
         setUserDetails(res.data.data);
      } else {
        navigate("/error-page");
      }
    } catch (error: any) {
      CustomToast(error.message || "An error occurred", "error");
      navigate("/error-page");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pathname === "/user-signup" && firstTime.current) {
      const queryParams = new URLSearchParams(location.search);
      const parseToken = queryParams.get("token");
      const token = parseToken && parseToken.replace(/\s+/g, "+");

      if (!token) {
        navigate("/error-page");
      } else {
        validateToken(token);
      }
      firstTime.current = false;
    }
  }, [location.pathname, location.search, navigate, doApi, firstTime]);

  const handleConfirm = async (values: FormValues, resetForm: any) => {
    setLoading(true);
    if (pathname === "/user-signup") {
      try {
        const finalValues = {...values, userId: userDetails.CreatedBy, teamMemberRoleId: userDetails.TeamMemberRoleId}
        let userConfig: IAPIConfig = {
          URL: "/create-user-profile-with-email",
          method: "POST",
          payLoad: finalValues
        };

        const res: any = await doApi(userConfig, "seedmain");
        if (res.data.success === true) {
          CustomToast(
            `User Profile created successfully`,
            "success"
          );
          navigate(`/login`);
        } else {
          CustomToast(
            `User Profile not created successfully`,
            "error"
          );
        }
      } catch (error: any) {
        CustomToast(error.message || "An error occurred", "error");
      } finally {
        setLoading(false);
      }
    } else {
      try {
        let userConfig: IAPIConfig;

        if (!update) {
          userConfig = {
            URL: "/create-user-profile",
            method: "POST",
            payLoad: values,
          };
        } else {
          userConfig = {
            URL: "/update-user-profile",
            method: "POST",
            payLoad: values,
          };
        }
        const res: any = await doApi(userConfig, "seedmain");
        if (res.data.success === true) {
          if(!update){
             sendEmailInvitation(values.Email, urlConstants.ENV_URL);
             setTimeout(()=>{
              navigate('/view-users');
             }, 1000)
             
          }else{
            sendPushNotifications({
              title: `${values.FirstName} ${values.LastName}`,
              body: `User Profile ${update ? "updated" : "created"} successfully`,
            });
          }
        } else {
          if(update){
            CustomToast(
              `User Profile not ${update ? "updated" : "created"} successfully`,
              "error"
            );
         }
        
        }
      } catch (error: any) {
        CustomToast(error.message || "An error occurred", "error");
      } finally {
        resetForm();
        dispatch(userProfile({}));
        setLoading(false);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    RoleId: Yup.string().required("User Type is required"),
    FirstName: Yup.string().required("First name is required"),
    LastName: Yup.string().required("Last name is required"),
    Email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    // Password: Yup.string()
    //   .min(6, "Password must be at least 6 characters")
    //   .required("Password is required"),
    // ConfirmPassword: Yup.string()
    //   .oneOf([Yup.ref("Password")], "Passwords must match")
    //   .required("Confirm Password is required"),
    // MobileNo: Yup.string()
    //             .max(10, "Mobile No not exceeded 10 digits.")
    //             .required("Mobile number is required"),
  });

  return (
    <Loader spinning={loading}>
      <div className="userform-container">
        {!update && !editable && (
          <div className="usertitle" id="userform-title">
            Please create the user/entity profile here!
          </div>
        )}
        <Formik
          initialValues={{
            Id: formData.Id,
            RoleId: formData.RoleId ,
            Entity: formData.Entity || userDetails.Entity,
            Email: formData.Email || userDetails.Email,
            FirstName: formData.FirstName || "",
            LastName: formData.LastName || "",
            // Password: formData.Password || "",
            // ConfirmPassword: formData.ConfirmPassword || "",
            // MobileNo: formData.MobileNo || "",
            OrganzationName:
              formData.OrganzationName || userDetails.OrganzationName,
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleConfirm(values, resetForm);
            setSubmitting(false);
          }}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            handleSubmit,
            setFieldValue,
            errors,
            values,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <Form
              onSubmit={handleSubmit}
              className="ant-form ant-form-horizontal"
            >
              <AntForm.Item
                label="User Type"
                validateStatus={touched.RoleId && errors.RoleId ? "error" : ""}
                help={
                  touched.RoleId && errors.RoleId
                    ? (errors.RoleId as string)
                    : ""
                }
              >
                <Field name="RoleId">
                  {({ field }: FieldProps) => (
                    <Select
                      {...field}
                      value={field.value}
                      onChange={(value) => setFieldValue("RoleId", value)}
                      onBlur={() => setFieldValue("RoleId", field.value)}
                      disabled={editable || pathname === "/user-signup"}
                    >
                       {pathname === "/user-signup"? (<Option value={1}>User</Option>): (update || editable) ? (<><Option value={3}>Agency</Option><Option value={1}>User</Option></>): (<Option value={3}>Agency</Option>)} 
                    </Select>
                  )}
                </Field>
              </AntForm.Item>
              <AntForm.Item
                label="Entity"
                validateStatus={touched.Entity && errors.Entity ? "error" : ""}
                help={
                  touched.Entity && errors.Entity
                    ? (errors.Entity as string)
                    : ""
                }
              >
                <Field name="Entity">
                  {({ field }: FieldProps) => (
                    <Select
                      {...field}
                      value={field.value}
                      onChange={(value) => setFieldValue("Entity", value)}
                      onBlur={() => setFieldValue("Entity", field.value)}
                      disabled={editable || pathname === "/user-signup"}
                    >
                      <Option value="organization">Organization</Option>
                      {/* <Option value="individual">Individual</Option> */}
                    </Select>
                  )}
                </Field>
              </AntForm.Item>
              {/* Conditionally render Organization Name when Entity is "organization" */}
              {values.Entity === "organization" && (
                <AntForm.Item
                  label="Organization Name"
                  validateStatus={
                    touched.OrganzationName && errors.OrganzationName
                      ? "error"
                      : ""
                  }
                  help={
                    touched.OrganzationName && errors.OrganzationName
                      ? (errors.OrganzationName as string)
                      : ""
                  }
                >
                  <Field name="OrganzationName">
                    {({ field }: FieldProps) => (
                      <Input
                        {...field}
                        disabled={editable || pathname === "/user-signup"}
                      />
                    )}
                  </Field>
                </AntForm.Item>
              )}

              <AntForm.Item
                label="First Name"
                validateStatus={
                  touched.FirstName && errors.FirstName ? "error" : ""
                }
                help={
                  touched.FirstName && errors.FirstName
                    ? (errors.FirstName as string)
                    : ""
                }
              >
                <Field name="FirstName">
                  {({ field }: FieldProps) => (
                    <Input {...field} disabled={editable} />
                  )}
                </Field>
              </AntForm.Item>

              <AntForm.Item
                label="Last Name"
                validateStatus={
                  touched.LastName && errors.LastName ? "error" : ""
                }
                help={
                  touched.LastName && errors.LastName
                    ? (errors.LastName as string)
                    : ""
                }
              >
                <Field name="LastName">
                  {({ field }: FieldProps) => (
                    <Input {...field} disabled={editable} />
                  )}
                </Field>
              </AntForm.Item>

              <AntForm.Item
                label="Email"
                validateStatus={touched.Email && errors.Email ? "error" : ""}
                help={
                  touched.Email && errors.Email ? (errors.Email as string) : ""
                }
              >
                <Field name="Email">
                  {({ field }: FieldProps) => (
                    <Input
                      {...field}
                      disabled={editable || pathname === "/user-signup"}
                    />
                  )}
                </Field>
              </AntForm.Item>

              {/* <AntForm.Item
                label="Password"
                validateStatus={
                  touched.Password && errors.Password ? "error" : ""
                }
                help={
                  touched.Password && errors.Password
                    ? (errors.Password as string)
                    : ""
                }
              >
                <Field name="Password">
                  {({ field }: FieldProps) => (
                    <Input.Password {...field} disabled={editable} />
                  )}
                </Field>
              </AntForm.Item>

              <AntForm.Item
                label="Confirm Password"
                validateStatus={
                  touched.ConfirmPassword && errors.ConfirmPassword
                    ? "error"
                    : ""
                }
                help={
                  touched.ConfirmPassword && errors.ConfirmPassword
                    ? (errors.ConfirmPassword as string)
                    : ""
                }
              >
                <Field name="ConfirmPassword">
                  {({ field }: FieldProps) => (
                    <Input.Password {...field} disabled={editable} />
                  )}
                </Field>
              </AntForm.Item>

              <AntForm.Item
                label="Mobile No"
                validateStatus={
                  touched.MobileNo && errors.MobileNo ? "error" : ""
                }
                help={
                  touched.MobileNo && errors.MobileNo
                    ? (errors.MobileNo as string)
                    : ""
                }
              >
                <Field name="MobileNo">
                  {({ field }: FieldProps) => (
                    <Input {...field} disabled={editable} />
                  )}
                </Field>
              </AntForm.Item> */}

              {!editable && (
                <AntForm.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!isValid || isSubmitting || editable}
                    loading={isSubmitting}
                  >
                    {update ? "Update" : "Submit"}
                  </Button>
                </AntForm.Item>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Loader>
  );
};

export default CreateUser;
