import "./SMSPreviewDrawer.scss";
import "./ReviewEmailEditScreen.scss";
import "./ReviewEmailEditScreen.scss";

import { Box, Button, IconButton, Stack, Typography } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { useSelector } from "react-redux";

type DrawerComponentProps = {
  open: boolean;
  toggleDrawer: any;
  data?: any[];
  preview?: string;
  editModeOn: any;
};

const SMSPreviewDrawer: React.FC<DrawerComponentProps> = ({
  open,
  toggleDrawer,
  data = [],
  preview,
  editModeOn
}) => {

  const profileData = useSelector(
    (state: any) => state.businessDetails.profileDataByUniqueID
  );
  const currentIndex = data.findIndex((item: any) => item.UniqueId === preview);
  const currentPreview = data[currentIndex];

  const handleNavigation = (direction: "prev" | "next") => {
    const newIndex = direction === "prev" ? currentIndex - 1 : currentIndex + 1;
    if (newIndex >= 0 && newIndex < data.length) {
      const newPreview = data[newIndex]?.UniqueId;
      toggleDrawer(true, newPreview)();
    }
  };

  const DrawerContent = () => {
    if (!currentPreview) return null;

    const { Name, Mode, Link, EmailInfo, SMSInfo } = currentPreview;

    if (Mode === "EMAIL") {
      return (
        <>
          <Box className="review-email-edit-screen-container schedule-settings">
            {/* Preview Area */}
            <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2 }}>
              {Name}
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
              Preview
            </Typography>
            <Stack className="phone-frame">
              <Typography variant="subtitle1" className="phone-frame-title">
                {EmailInfo?.Subject}
              </Typography>
              <Box className="phone-frame-content">
                <Box>
                  <Typography variant="h6" className="title">
                    {profileData?.Name}
                  </Typography>
                  <Typography variant="body1" className="content" sx={{ whiteSpace: "pre-wrap" }}>
                    {EmailInfo?.Content}
                    <br />
                  </Typography>
                </Box>
                <Box className="submit-btn">
                  <Button variant="contained" color="primary">
                    {EmailInfo?.SendText}
                  </Button>
                </Box>
              </Box>
            </Stack>
          </Box>
        </>
      );
    } else if (Mode === "SMS") {
      return (
        <>
          <Box className="review-email-edit-screen-container schedule-settings">
            {/* Preview Area */}
            <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2 }}>
              {Name}
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
              Preview
            </Typography>
            <Stack className="phone-frame">
              <Box
                className="sms-preview"
                sx={{
                  marginTop: 1.5,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box className="sms-message">
                  <Typography variant="body2">{SMSInfo?.Text}</Typography>
                  <Typography variant="body2" sx={{ marginTop: 2 }}>
                    {Link?.Url ? (
                      <Box component="span">{Link?.Url}</Box>
                    ) : (
                      <span></span>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </>
      );
    }
    return null;
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)()}>
      <Box sx={{ minWidth: "304px", padding: "24px 32px", bgcolor: "#F6F8F8" }}>
        <Stack direction="row" justifyContent="flex-end">
          <CloseIcon
            sx={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
              color: "#0F4343",
            }}
            onClick={() => toggleDrawer(false)()}
          />
        </Stack>
        <Box>{DrawerContent()}</Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginTop: 3 }}
        >
          <Stack direction="row" spacing={1}>
            <IconButton
              sx={{ border: "1px solid rgba(51, 132, 132, 0.5)" }}
              onClick={() => handleNavigation("prev")}
              disabled={currentIndex <= 0}
            >
              <ArrowBackIcon
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                  color:
                    currentIndex > 0
                      ? "rgba(15, 67, 67, 1)"
                      : "rgba(51, 132, 132, 0.5)",
                }}
              />
            </IconButton>
            <IconButton
              sx={{ border: "1px solid rgba(15, 67, 67, 1)" }}
              onClick={() => handleNavigation("next")}
              disabled={currentIndex >= data.length - 1}
            >
               <ArrowForwardIcon
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                  color:
                    currentIndex < data.length - 1
                      ? "rgba(15, 67, 67, 1)"
                      : "rgba(51, 132, 132, 0.5)",
                }}
              />
            </IconButton>
          </Stack>
          <Box>
            <IconButton sx={{ border: "1px solid #0F4343" }}>
              <EditIcon
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                  color: "rgba(15, 67, 67, 1)",
                }}
                onClick={() => editModeOn(currentPreview)}
              />
            </IconButton>
          </Box>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default SMSPreviewDrawer;
