import CustomToast from "../utils/cutomToast/CustomToast";
import { urlConstants } from "../components/constants/globalConstants";
import { useApi } from "./useApi";
import useSendPushNotifications from "./useSendPushNotifications";
import { useState } from "react";

interface OtpPayload {
  from: string;
  to: string;
  subject: string;
  cc: string;
  textbody: string;
  htmlbody: string;
}

interface IAPIConfig {
  URL: string;
  method: string;
  payLoad: any;
}

const useSendOtpEmail = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { doApi } = useApi();
  const { sendPushNotifications } = useSendPushNotifications();

  const sendOtpEmail = async (email: string, otp: string) => {
    setLoading(true);
    setError(null);

    const emailPayload: OtpPayload = {
      from: urlConstants.SENDER_EMAIL_ID,
      to: email,
      subject: `${urlConstants.DOMAIN} login verification`,
      cc: "",
      textbody: `Dear User,\n\nYour OTP code is: ${otp}\n\nPlease use this to verify your email.`,
      htmlbody: `
        <p>Dear User,</p>
        <p>Your verification code is:</p>
        <h2 style="color: #4CAF50;">${otp}</h2>
        <p>Please use this code to verify your email.</p>
      `,
    };

    const sendOtpConfig: IAPIConfig = {
      URL: `/Vault/SendVerifyCode?signKey=${urlConstants.Sign_Key}`,
      method: "POST",
      payLoad: emailPayload,
    };

    try {
      const otpRes: any = await doApi(sendOtpConfig, "seedcore");
      if (otpRes.data.err === false) {
        sendPushNotifications({
          title: `OTP sent to`,
          body: `${email}`,
        });
        CustomToast(`OTP sent successfully to ${email}`, "success");
      } else {
        throw new Error(`Failed to send OTP to ${email}`);
      }
    } catch (err: any) {
      setError(err.message || "An unexpected error occurred");
      CustomToast(`Failed to send OTP to ${email}`, "error");
    } finally {
      setLoading(false);
    }
  };

  return { sendOtpEmail, loading, error };
};

export default useSendOtpEmail;
