import "./Homepage.scss";

import { Box, Button, Drawer, TextField, Typography } from "@mui/material";
import { HomeOutlined, SettingsOutlined } from "@mui/icons-material";
import { Outlet, useNavigate } from "react-router-dom";
import { setExpandedBusiness, setProfileData } from "../../store/businessSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BusinessSidebar from "../../components/Sidebar/BusinessSidebar";
import IconButton from '@mui/material/IconButton';
import Navbar from "../../components/Navbar/AdminNavbar";
import SearchIcon from '@mui/icons-material/Search';
import Sidebar from "../../components/Sidebar/AdminSidebar";
import { urlConstants } from "../../components/constants/globalConstants";

function SidebarContent() {

  const navigate = useNavigate();
  const { userDetails } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState(""); // Manage search term

  // Handle search input change from child component
  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const searchRef = useRef<HTMLDivElement | null>(null);
  const [showSearch, setShowSearch] = useState<boolean>(false);

   // Toggle search input visibility
   const handleSearchToggle = () => {
    setShowSearch(!showSearch);
  };


    // Click outside logic to close the search input
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
          setShowSearch(false); // Close search input when clicking outside
        }
      }
  
      // Add event listener to detect outside clicks
      if (showSearch) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
  
      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [showSearch]);

    const expandedBusiness = useSelector((state : any) => state.businessDetails.expandedBusiness); 

  return (
    <Box className="homepage" >
      <Box className="sidebar-drawer">
        <Box className="sidebar-header">
          <Box className="sidebar-title">
            <Box className="title">
              <Box className="logo">
                <Typography variant="h5" sx={{ color: "white" }}>
                  massic.io
                </Typography>
              </Box>
              <Box className="line"></Box>
            </Box>
           {(userDetails.roleid !== 2) && <Button
              color="primary"
              size="medium"
              variant="contained"
              startIcon={<HomeOutlined />}
              onClick={() => 
              { navigate("/home");
                dispatch(setExpandedBusiness(null));
                dispatch(setProfileData([]));
              }}
            >
              Dashboard
            </Button>}
          </Box>
          <Box className="sidebar-body">
            <Sidebar />
          
            {/* Search input field (conditionally rendered) */}
            {(userDetails.roleid !== 2) && (showSearch ? (
              <Box sx={{ marginLeft: "5px" }} ref={searchRef}>
               <TextField
                  // label="Enter Business Name"
                  placeholder="Enter Business Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      color: "white", // Input text color
                      "& fieldset": {
                        borderColor: "rgba(255, 255, 255, 0.6)", // Border color
                      },
                      "&:hover fieldset": {
                        borderColor: "rgba(255, 255, 255, 0.6)", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(255, 255, 255, 0.6)", // Border color when focused
                      },
                      // height: "35px",
                      padding: "0px",
                    },
                    "& .MuiInputLabel-root": {
                      color: "rgba(255, 255, 255, 0.6)", // Label color with reduced opacity (60% opacity)
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "rgba(255, 255, 255, 1)", // Label color with full opacity when focused
                    },
                    ".MuiOutlinedInput-input":{
                      padding: "6px 14px",
                      fontSize: "14px",
                    }
                  }}
                  onChange={handleSearchInput}  
                  value={searchTerm}
                />
              </Box>
              )
              :
              <Box className="sidebar-body-label" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Typography
                variant="subtitle2"
                className="sidebar-body-label-name"
              >
                BUSINESSES
              </Typography>
              <Box>
                <IconButton aria-label="delete" sx={{color: 'white', marginRight: '16px', width: '14px', height: '14px'}} onClick={() => navigate("/create-business") } >
                  <AddCircleOutlineIcon  />
                </IconButton>
                <IconButton aria-label="delete" sx={{color: 'white', width: '14px', height: '14px'}} onClick={handleSearchToggle} >
                  <SearchIcon  />
                </IconButton>
              </Box>
              </Box>
            )}
            <Box className="business-sidebar" sx={{
            
             flex: 1, // Allows this section to grow and occupy available space
             overflowY: 'auto', // Enables vertical scrolling
            maxHeight: 'calc(100vh - 360px)',
            width: '100%',
            }} >
              <BusinessSidebar expandedBusiness={expandedBusiness} setExpandedBusiness={setExpandedBusiness} searchTerm={searchTerm} />
            </Box>
          </Box>
        </Box>
        <Box className="sidebar-footer">
          <Box className="line"></Box>
          <Box className="company-name">
            <Box className="company-name-label">
              <Typography variant="subtitle2" className="sidebar-footer-title">
                {userDetails.organzationName}
              </Typography>
            </Box>
            <Box className="settings-button">
              <Button
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<SettingsOutlined />}
                onClick={() => {
                  navigate("/settings");
                }}
              >
                {" "}
                Settings{" "}
              </Button>
            </Box>
          </Box>
          <Box className="version">
            <Typography variant="subtitle2" className="sidebar-footer-version">
              {urlConstants.MASSIC_VERSION}
            </Typography>
          </Box>
        </Box>
      </Box>
  </Box>

  )

}

const AdminHomepage = () => {


  // const [expandedBusiness, setExpandedBusiness] = useState<string | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Function to toggle sidebar visibility
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <Box className="homepage">

      <Box>
        {/* Sidebar for larger screens */}
        <Box
          sx={{
            display: { xs: "none !important", md: "flex !important" },
            position: "relative",
            flexDirection: "column",
            width: "15rem",
            height: "100%",
            backgroundColor: "var(--massic-800, #093535)",
           

          }}
        >
          <SidebarContent /> {/* Reuse the SidebarContent component here */}
        </Box>
        {/* Drawer for mobile/tablet screens */}
        <Drawer
          anchor="left"
          open={isSidebarOpen}
          onClose={toggleSidebar}
          sx={{
            display: { xs: "block", md: "none" }, // Show on mobile/tablet
            "& .MuiDrawer-paper": {
              width: "15rem",
              backgroundColor: "var(--massic-800, #093535)",
            },
            height: '100vh'
          }}
        >
        <SidebarContent />
        </Drawer>
      </Box>

      <Box className="container-body">
        <Navbar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        <Outlet />
      </Box>

    </Box>
  );
};

export default AdminHomepage;
